import axios from "axios";
import { useQuery } from "react-query";

const getEventResolutions = async (eventId) => {
  const { data } = await axios.get(
    `/api/v1/resolutions/event/${eventId}`
  );
  return data;
};

export default function useEventResolutions(eventId) {
  const fallback = [];
  const { data = fallback, status } = useQuery(
    ["resolutions", eventId],
    () => getEventResolutions(eventId),
    {
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      staleTime: 5000,
      enabled: !!eventId,
    }
  );
  return { data, status };
}
