import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { usePartiesList } from "../../parties/hooks/useParties";
import { useStatusList } from "../../status/hooks/useStatus";
import { useThemesList } from "../../themes/hooks/useThemes";
import { useEventsList } from "../../events/hooks/useEvents";
import useResolution from "../hooks/useResolution";

import EditResolutioForm from "./EditResolutioForm";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";

const EditResolution = (props) => {
  const { eventSlug, eventId, eventType, eventTitle } = props;

  const isMainParty = eventType === 1 || false;
  const { resolutionId } = useParams();
  const { data: resolution, status: resolutionStatus } =
    useResolution(resolutionId);

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: eventTitle, location: `events/${eventSlug}` },
      {
        text: "Résolution",
        location: `events/${eventSlug}/resolution/${resolutionId}`,
      },
      { text: "Modifier la résolution", location: `events/${eventSlug}` },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb, eventSlug, eventTitle, resolutionId]);
  // const { mutateAsync: newResolution } = useAddResolution();
  const [proposers, setProposers] = useState();
  const [contacts, setContacts] = useState();
  const [whereas, setWhereas] = useState();

  const { events } = useEventsList();
  const { parties } = usePartiesList();
  const { status } = useStatusList();
  const { themes } = useThemesList();

  useEffect(() => {
    if (resolution.resolution_proposers) {
      setProposers(
        resolution.resolution_proposers.map((proposer) => {
          var newObj = {};
          newObj["id"] = proposer.id;
          newObj["instance_id"] = proposer.instance_id;
          if (proposer.region_id) {
            newObj["region_id"] = proposer.region_id;
          } else {
            newObj["district_id"] = proposer.district_id;
          }

          return newObj;
        })
      );
    }
  }, [resolution]);

  useEffect(() => {
    if (resolution.contacts) {
      setContacts(
        resolution.contacts.map((contact) => {
          var newObj = {};
          newObj["id"] = contact.id;
          newObj["email"] = contact.email;
          newObj["first_name"] = contact.first_name;
          newObj["last_name"] = contact.last_name;
          newObj["membership"] = contact.membership;
          newObj["isResource"] = contact.isResource;
          newObj["phone"] = contact.phone;
          return newObj;
        })
      );
    }
  }, [resolution]);

  useEffect(() => {
    if (resolution.whereas) {
      setWhereas(
        resolution.whereas.map((whereas) => {
          var newObj = {};
          newObj["id"] = whereas.id;
          newObj["title"] = whereas.title;
          newObj["order"] = whereas.order;
          return newObj;
        })
      );
    }
  }, [resolution]);

  if (resolutionStatus === "loading") {
    return "Loading...";
  }

  if (!resolution || !resolution.resolution_proposers) {
    return "Loading...";
  }

  if (!proposers) {
    return "Loading...";
  }

  // console.log(eventSlug);

  return (
    <>
      <EditResolutioForm
        parties={parties}
        status={status}
        themes={themes}
        events={events}
        eventSlug={eventSlug}
        eventId={eventId}
        resolution={resolution}
        proposers={proposers}
        whereas={whereas}
        contacts={contacts}
        isMainParty={isMainParty}
      />
    </>
  );
};

export default EditResolution;
