import { useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import NavLink from "../../../shared/components/NavLink";
import useEvent from "../../events/hooks/useEvent";
import { useData } from "../context/form-context";

import Step1 from "../resolution/Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Summary from "./Summary";

const Resolution = () => {
  const { eventSlug } = useParams();
  const { data: event, status } = useEvent(eventSlug);
  const { isStepValid } = useData();
  const [isCompleted, setIsCompleted] = useState(false);

  if (status === "loading") {
    return "";
  }

  if ((status === "success" && !event) || !event.isActive) {
    return (
      <div
        className="uk-text-center uk-flex uk-flex-middle uk-flex-center"
        data-uk-height-viewport
      >
        <h1 className="uk-margin-large-bottom">404</h1>
      </div>
    );
  }
  return (
    <div className="">
      <div className="uk-section uk-section-secondary uk-section-small">
        <div className="uk-container">
          <div className="uk-text-center">
            <h4 className="uk-margin-remove">{event.title}</h4>
            <h1 className="uk-margin-remove">Proposer une résolution</h1>
          </div>
        </div>
      </div>

      <div className="uk-section uk-section-small">
        <div className="uk-width-4-5@m uk-width-2-3@l uk-margin-auto">
          {isCompleted && (
            <div className="uk-width-4-5@m uk-width-2-3@l uk-margin-auto">
              <div className="uk-alert-success" data-uk-alert>
                <p>Merci, votre résolution a bien été reçu.</p>
              </div>
            </div>
          )}
          <div className="uk-width-4-5@m uk-width-2-3@l uk-margin-auto">
            <ul className="form-navigation">
              <NavLink
                to=""
                className={`${
                  isStepValid[0].validity ? "valid-step " : "invalid-step "
                }`}
                activeClassName="uk-active"
              >
                <span className="text">Informations</span>
              </NavLink>
              <NavLink
                to="step-2"
                className={`${
                  isStepValid[1].validity ? "valid-step " : "invalid-step "
                } ${isStepValid[0].validity ? "clickable-step " : " "}`}
                activeClassName="uk-active"
              >
                <span className="text">Proposeurs</span>
              </NavLink>
              <NavLink
                to="step-3"
                className={`${
                  isStepValid[2].validity ? "valid-step " : "invalid-step "
                } ${isStepValid[1].validity ? "clickable-step " : " "}`}
                activeClassName="uk-active"
              >
                <span className="text">Résolution</span>
              </NavLink>
              <NavLink
                to="summary"
                className={`invalid-step  ${
                  isStepValid[2].validity ? "clickable-step " : " "
                }`}
                activeClassName="uk-active"
              >
                <span className="text">Résumé</span>
              </NavLink>
            </ul>
          </div>
        </div>
      </div>

      <div className="uk-section uk-padding-remove-top">
        <div className="uk-container">
          <div className="uk-width-4-5@m uk-width-2-3@l uk-margin-auto">
            <div className="uk-card uk-card-default uk-padding">
              <Routes>
                <Route
                  path="/"
                  element={<Step1 setIsCompleted={setIsCompleted} />}
                />
                <Route
                  path="/step-2"
                  element={
                    <Step2
                    event={event}
                    />
                  }
                />
                <Route
                  path="/step-3"
                  element={<Step3 eventSlug={eventSlug} />}
                />
                <Route
                  path="/summary"
                  element={
                    <Summary
                      eventSlug={eventSlug}
                      setIsCompleted={setIsCompleted}
                    />
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resolution;
