import { Route, Routes } from "react-router-dom";
import ViewStatus from "./index/ViewStatus";


const Status = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewStatus />} />
    </Routes>
  );
};

export default Status;
