import axios from "axios";
// import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

const editEvent = async (request) => {
  // console.log(request);
  const { data: response } = await axios
    .patch(
      `/api/v1/events/edit/${request.eventId}`,
      request.values
    )
    .then((res) => res);
  return response.data;
};

export default function useEditEvent() {
  const queryClient = useQueryClient();
  return useMutation((variables) => editEvent(variables), {
    onError: (error, variables, context) =>
      queryClient.setQueryData("event", context.slug),

    onSuccess: (data, variables, context) => {
      // console.log({ variables });
      //  queryClient.invalidateQueries("events", variables.id);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries("event", variables.slug);
    },
    throwOnError: true,
  });
}
