import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";
import { useButtonToolbarSetter } from "../../../shared/components/page-header/context/buttons-bar-context";
import { useUpdateTitle } from "../../../shared/components/page-header/context/title-context";
import { formatDate } from "../../../shared/util/formatDate";
import useDeleteEvent from "../hooks/useDeleteEvent";
import useEvents from "../hooks/useEvents";

const ViewEvents = () => {
  const { authState } = useContext(AuthContext);

  const updateTitle = useUpdateTitle();
  useEffect(() => {
    updateTitle({ title: "Événements" });
  }, [updateTitle]);
  const { data: events } = useEvents();

  const { mutateAsync: deleteEvent } = useDeleteEvent();

  const onDeleteButtonClick = async (eventId, slug) => {
    try {
      await deleteEvent({ eventId, slug });
    } catch (error) {
      console.log(error);
    }
  };

  const setButtonToolBar = useButtonToolbarSetter();

  useEffect(() => {
    setButtonToolBar([
      authState.userInfo.role === "admin" && (
        <Link
          key="newEventButton"
          to="events/add"
          className="uk-button uk-button-primary"
        >
          Ajouter un événement
        </Link>
      ),
    ]);
    return () => {
      setButtonToolBar([]);
    };
  }, [setButtonToolBar, authState.userInfo.role]);

  const setBreadcrumb = useBreadcrumbSetter();

  useEffect(() => {
    setBreadcrumb([{ text: "Événement", location: "/events" }]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb]);

  return (
    <div className="uk-card uk-card-body uk-card-default ">
      {events.length ? (
        <>
          <table className="uk-table uk-table-divider">
            <thead>
              <tr>
                <th>Titre</th>
                <th>Date</th>
                <th>Statut</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id}>
                  <td>
                    <Link to={`${event.slug}`}>{event.title}</Link>
                  </td>
                  <td>{formatDate(event.date)}</td>
                  <td>{event.isActive ? "Actif" : "Inactif"}</td>
                  <td>{event.event_type?.title}</td>

                  <td>
                    <Link to={`${event.slug}`}>Voir</Link>
                    {authState.userInfo.role === "admin" && (
                      <>
                        {" "}- <Link to={`edit/${event.slug}`}>Modifier</Link> -{" "}
                        <button
                          className="uk-button uk-button-link"
                          onClick={(e) =>
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            ) && onDeleteButtonClick(event.id, event.slug)
                          }
                        >
                          Supprimer
                        </button>
                      </>
                    )}
                    {event.isActive && event.event_type_id === 1 && (
                      <Link to={`/${event.slug}`} target="_blank">
                        {" "}
                        - Voir le formulaire
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="uk-text-center">
          <h3 className="uk-margin-small">Aucun événement</h3>
          {authState.userInfo.role === "admin" && (
            <Link to="add" className="uk-button uk-button-primary">
              Ajouter un événement
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewEvents;
