import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import { formatDate } from "../../../shared/util/formatDate";
import useEventResolutions from "../hooks/useEventResolutions";
import useDeleteResolution from "../../resolutions/hooks/useDeleteResolution";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";

const ResolutionsTable = ({ eventId, eventSlug, eventTitle }) => {
  const { authState } = useContext(AuthContext);

  const navigate = useNavigate();
  const { data: resolutions } = useEventResolutions(eventId);

  const { mutateAsync: deleteResolution } = useDeleteResolution();

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: eventTitle, location: `events/${eventSlug}` },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb, eventSlug, eventTitle]);

  const onDeleteButtonClick = async (resolutionId) => {
    try {
      await deleteResolution({ resolutionId });
      navigate(`/app/events/${eventSlug}`);
    } catch (error) {
      console.log(error);
    }
  };

  if (!eventId) {
    return " Loading...";
  }

  if (resolutions.length <= 0) {
    return (
      <div className="uk-card uk-card-body uk-card-default">
        <div className="uk-text-center">
          <h3 className="uk-margin-small">Aucune résolution</h3>
          {authState.userInfo.role === "admin" && (
            <button
              type="button"
              className="uk-button uk-button-primary uk-button-small"
              // onClick={() => downloadCSV()}
              onClick={() => {
                navigate(`resolution/add`);
              }}
            >
              + Ajouter une résolution
            </button>
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="uk-card uk-card-body uk-card-default uk-margin-top">
      <div className="uk-flex uk-flex-between">
        <h3>Résolutions</h3>
        <div>
          {/* <button
            type="button"
            className="uk-button uk-button-small uk-button-primary"
            // onClick={() => downloadCSV()}
          >
            Exporter
          </button> */}
          {authState.userInfo.role === "admin" && (
            <button
              type="button"
              className="uk-button uk-button-primary uk-button-small "
              // onClick={() => downloadCSV()}
              onClick={() => {
                navigate(`resolution/add`);
              }}
            >
              + Ajouter une résolution
            </button>
          )}
        </div>
      </div>
      <table className="uk-table uk-table-divider uk-table-hover">
        <thead>
          <tr>
            <th className="uk-text-center">Finale ?</th>
            <th className="uk-text-center">Révisée ?</th>
            <th>Titre</th>
            <th>Date</th>
            <th>Statut</th>
            <th>Proposeur</th>
            <th>Parti</th>
            {authState.userInfo.role !== "read" && (
              <th className="uk-text-center">Traduction</th>
            )}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {resolutions.length > 0 &&
            resolutions.map((resolution) => {
              let translationStatus = (
                <Link to={`resolution/translate/${resolution.id}`}>
                  <i className="fas fa-pen"></i>
                </Link>
              );
              if (resolution.title_en && resolution.content_en) {
                translationStatus = <i className="fas fa-check-square"></i>;
                resolution.whereas.map((where) => {
                  if (!where.title_en) {
                    translationStatus = (
                      <Link to={`resolution/translate/${resolution.id}`}>
                        <i className="fas fa-pen"></i>
                      </Link>
                    );
                  }
                  return translationStatus;
                });
              }
              return (
                <tr key={resolution.id}>
                  <td className="uk-text-center">
                    {resolution.isFinal ? (
                      <i className="fas fa-check-circle"></i>
                    ) : (
                      <i className="fas fa-times-circle"></i>
                    )}
                  </td>
                  <td className="uk-text-center">
                    {resolution.isRevised ? (
                      <i className="fas fa-check-circle"></i>
                    ) : (
                      <i className="fas fa-times-circle"></i>
                    )}
                  </td>
                  <td>
                    <Link to={`resolution/${resolution.id}`}>
                      {resolution.title}
                    </Link>
                  </td>
                  <td>{formatDate(resolution.date)}</td>
                  <td>{resolution.status.label}</td>
                  <td>
                    {resolution.resolution_proposers.map((proposer) => (
                      <div key={proposer.id}>
                        {proposer.instance.label}
                        {(proposer.district || proposer.region) && <> : </>}
                        {proposer.district_id
                          ? proposer.district?.label
                          : proposer.region?.label}{" "}
                      </div>
                    ))}
                  </td>

                  <td>{resolution.party.value}</td>
                  {authState.userInfo.role !== "read" && (
                    <td className="translation-row">{translationStatus}</td>
                  )}
                  <td>
                    <Link to={`resolution/${resolution.id}`}>Voir</Link>
                    {authState.userInfo.role === "admin" && (
                      <>
                        {" "}
                        -{" "}
                        <Link
                          to={`/public/resolution/${resolution.publicLink}`}
                          target="_blank"
                        >
                          Public
                        </Link>
                      </>
                    )}
                    {(authState.userInfo.role === "admin" ||
                      authState.userInfo.role === "reviseur") && (
                      <>
                        {" "}
                        -{" "}
                        <Link to={`resolution/edit/${resolution.id}`}>
                          Modifier
                        </Link>{" "}
                      </>
                    )}
                    {authState.userInfo.role === "admin" && (
                      <>
                        -{" "}
                        <button
                          className="uk-button uk-button-link"
                          onClick={(e) =>
                            window.confirm(
                              "Are you sure you wish to delete this item?"
                            ) && onDeleteButtonClick(resolution.id)
                          }
                        >
                          Supprimer
                        </button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ResolutionsTable;
