import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const deleteResolution = async (request) => {
  const { data: response } = await axios
    .delete(`/api/v1/resolutions/delete/${request.resolutionId}`)
    .then((res) => res);
  return response.data;
};

export default function useDeleteResolution() {
  const queryClient = useQueryClient();
  return useMutation((variables) => deleteResolution(variables), {
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData("resolution", previousValue),
    onSuccess: (data, variables, context) => {},
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries("resolutions");
    },
    throwOnError: true,
  });
}
