import { useParams, Routes, Route } from "react-router-dom";
import { formatDate } from "../../../shared/util/formatDate";
import useEvent from "../hooks/useEvent";
import EditResolution from "../../resolutions/edit/EditResolution";
import NewResolution from "../../resolutions/add/NewResolution";
// import NewResolution from "../resolutions/NewResolution";
import ResolutionsTable from "../resolutions/ResolutionsTable";
import ViewResolution from "../resolutions/ViewResolution";
import EditResolutionTranslation from "../../resolutions/edit/EditResolutionTranslation";
import { useUpdateTitle } from "../../../shared/components/page-header/context/title-context";
import { useEffect } from "react";


const ViewEvent = () => {
  const { eventSlug } = useParams();
  const { data: event } = useEvent(eventSlug);
  const updateTitle = useUpdateTitle();



  useEffect(() => {
    updateTitle({
      title: event.title,
      extra: (
        <div>
          <b>{formatDate(event.date)}</b>
          <span
            className={`uk-margin-left uk-label ${
              event.isActive ? "uk-label-success" : "uk-label-danger"
            }`}
          >
            {event.isActive ? "Actif" : "Inactif"}
          </span>
        </div>
      ),
    });
  }, [event, updateTitle]);

  if (!event.id) {
    return "Loading...";
  }

  let eventId = event.id;

  return (
    <Routes>
      <Route
        path="/"
        element={<ResolutionsTable eventId={eventId} eventSlug={event.slug} eventTitle={event.title} />}
      />
      <Route
        path="resolution/:resolutionId"
        element={
          <ViewResolution eventSlug={event.slug} eventTitle={event.title} />
        }
      />
      <Route
        path="resolution/add"
        element={
          <NewResolution
            eventSlug={event.slug}
            eventId={event.id}
            eventType={event.event_type_id}
            eventTitle={event.title}
          />
        }
      />
      <Route
        path="resolution/edit/:resolutionId"
        element={
          <EditResolution
            eventSlug={event.slug}
            eventId={event.id}
            eventType={event.event_type_id}
            eventTitle={event.title}
          />
        }
      />
      <Route
        path="resolution/translate/:resolutionId"
        element={<EditResolutionTranslation eventSlug={event.slug} eventTitle={event.title} />}
      />
    </Routes>
    // </div>
  );
};

export default ViewEvent;
