import { useNavigate } from "react-router";
import { useBreadcrumb } from "../context/breadcrumb-context";

const Breadcrumbs = () => {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();

  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <div>
      <ul className="uk-breadcrumb">
        {/* <li>wd</li> */}
        {breadcrumbs.map(({ location, text }, index) => {
          const isLast = index === breadcrumbs.length - 1;
          const onClick = !isLast ? () => navigate(location) : undefined;
          return (
            <li key={index}>
              {isLast ? (
                <span>{text}</span>
              ) : (
                <button
                  type="button"
                  onClick={onClick}
                  className="uk-button uk-button-link"
                >
                  {text}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
