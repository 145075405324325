import { Route, Routes } from "react-router-dom";

import ViewEvents from "./index/ViewEvents";
import NewEvent from "./add/NewEvent";
import ViewEvent from "./view/ViewEvent";
import EditEvent from "./edit/EditEvent";

const Events = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewEvents />} />
      <Route path="/add" element={<NewEvent />} />
      <Route path="/edit/:eventSlug" element={<EditEvent />} />
      <Route path=":eventSlug/*" element={<ViewEvent />} />
    </Routes>
  );
};

export default Events;
