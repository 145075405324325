const Input = (props) => {
  const {
    name,
    label,
    register,
    type,
    isReadonly,
    defaultValue,
    required,
    errors,
  } = props;

  let inputElement;


  if (type === "checkbox") {
    inputElement = (
      <input
        className={"uk-checkbox " + (isReadonly && "uk-disabled")}
        id={name}
        defaultChecked={defaultValue}
        {...register(name)}
        readOnly={isReadonly}
        type={type ? type : "text"}
      />
    );
  } else {
    inputElement = (
      <input
        className={"uk-input " + (isReadonly && "uk-disabled")}
        id={name}
        defaultValue={defaultValue}
        // value={data.contacts[index].first_name)}
        {...register(name, { required })}
        readOnly={isReadonly}
        type={type ? type : "text"}
      />
    );
  }

  return (
    <div>
      {type !== "hidden" && (
        <label className="uk-form-label" htmlFor={name}>
          {label}
          {required && <span className="tm-required"> *</span>}
        </label>
      )}
      <div className={`uk-form-controls`}>
        {inputElement}
        {errors && (
          <div className="uk-alert-danger uk-margin-small-top" data-uk-alert>
            {errors.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
