import ReactSelect from "react-select";
import { Controller } from "react-hook-form";

import { forwardRef } from "react";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "1px solid #e5e5e5",
    borderRadius: 0,
    fontSize: "15px",
    "&:hover": {
      boxShadow: state.isFocused ? " " : "none",
    },

    // This line disable the blue border
    boxShadow: state.isFocused ? "0 0 0 1px #007ac2" : "",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#666",
  }),
};

const Select = ({
  control,
  name,
  label,
  required,
  defaultValue = null,
  isReadonly,
  errors,
  options,
  isMulti = false,
  register,
  shouldUnregister,
}) => {
  const SelectWrapper = forwardRef(({ onChange, onBlur, value }, ref) => {
    return (
      <ReactSelect
        placeholder="Veuillez choisir"
        options={options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.label}
        styles={customStyles}
        isDisabled={isReadonly}
        {...{
          onChange: (item) => item && onChange(item.id),
          value: options && options.find((item) => item.id === value),
        }}
      />
    );
  });

  return (
    <>
      <label className="uk-form-label" htmlFor={name}>
        {label}
        {required && <span className="tm-required"> *</span>}
      </label>
      {isMulti ? (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          rule={register}
          shouldUnregister={shouldUnregister}
          render={({ field }) => (
            <ReactSelect
              {...field}
              styles={customStyles}
              options={options}
              isDisabled={isReadonly}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.label}
              isMulti
              placeholder={"Veuillez choisir"}
            />
          )}
        />
      ) : (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          shouldUnregister={shouldUnregister}
          render={({ field }) => <SelectWrapper {...field} />}
        />
      )}

      {errors && (
        <div className="uk-alert-danger uk-margin-small-top" data-uk-alert>
          {errors.message}
        </div>
      )}
    </>
  );
};

export default Select;
