import axios from "axios";
import { useQuery } from "react-query";

export const getUsers = async (isList) => {
  const { data } = await axios.get("/api/v1/users", { params: isList });
  return data;
};

export default function useUsers() {
  const fallback = [];
  const { data = fallback, status } = useQuery(["users"], () => getUsers(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
  });

  return { data, status };
}

