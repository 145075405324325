import { useForm } from "react-hook-form";
// import slugify from "react-slugify";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, date, string } from "yup";
import DateInput from "../../../shared/components/DateInput";
import Input from "../../../shared/components/Input";
import useEditEvent from "../hooks/useEditEvent";
import useEvent from "../hooks/useEvent";
import Select from "../../../shared/components/Select";
import { useEventTypesList } from "../hooks/useEventTypes";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";
import { useEffect } from "react";
// import { useEffect } from "react";

const EditEvent = () => {
  const schema = object().shape({
    title: string().required(),
    slug: string().required(),
    date: date().required(),
  });
  const { eventSlug } = useParams();
  const { data: event, status } = useEvent(eventSlug);
  const eventId = event.id;
  const {
    register,
    handleSubmit,
    control,
    // setValue,
    formState: {
      errors,
      // isValid,
    },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // const breadcrumbs = ;


  const navigate = useNavigate();
  //   const watchTitle = watch("title");
  const { mutateAsync: updateEvent } = useEditEvent();
  const { types } = useEventTypesList();

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: "Modifier un événement", location: `/events/edit/${eventSlug}` },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb, eventSlug]);

  //   useEffect(() => {
  //     setValue("slug", slugify(watchTitle));
  //   }, [setValue, watchTitle]);

  const onSubmit = async (values) => {
    try {
      //   values.date = new Date(values.date);
      await updateEvent({ eventId, values });
      navigate("/app/events");
    } catch (error) {}
  };

  if (status === "success" && !event) {
    return <div>Aucun événement</div>;
  }

  return (
    status === "success" && (
      <div className="uk-card uk-card-body uk-card-default">
        <div data-uk-grid>
          <div className="uk-width-2-5@m">
            <h3 className="uk-margin-small">Modifier l'événement</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="uk-margin">
                <Input
                  name="title"
                  label="Nom de l'événement"
                  register={register}
                  required
                  type="text"
                  errors={errors["title"]}
                  defaultValue={event.title}
                />
              </div>
              <div className="uk-margin">
                <Input
                  name="slug"
                  label="URL"
                  register={register}
                  type="text"
                  errors={errors["slug"]}
                  control={control}
                  required
                  defaultValue={event.slug}
                />
              </div>
              <div className="uk-margin">
                <Input
                  name="isActive"
                  label="Est actif ?"
                  register={register}
                  type="checkbox"
                  defaultValue={event.isActive}
                  errors={errors["isActive"]}
                  control={control}
                />
              </div>
              <div className="uk-margin">
                <DateInput
                  name="date"
                  label="Date"
                  control={control}
                  required
                  defaultValue={event.date}
                />
              </div>
              <div className="uk-margin">
                <Select
                  name="event_type_id"
                  label="Type d'événement"
                  control={control}
                  type="select"
                  options={types}
                  register={register}
                  defaultValue={event.event_type_id}
                  required
                />
              </div>
              <button
                onClick={handleSubmit(onSubmit)}
                className="uk-button uk-button-primary"
              >
                Enregistrer
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  );
};

export default EditEvent;
