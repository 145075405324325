import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const deleteEvent = async (request) => {
  const { data: response } = await axios
    .delete(`/api/v1/events/delete/${request.eventId}`)
    .then((res) => res);
  return response.data;
};

export default function useDeleteEvent() {
  const queryClient = useQueryClient();
  return useMutation((variables) => deleteEvent(variables), {
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData("event", previousValue),
    onSuccess: (data, variables, context) => {},
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries("events");
    },
    throwOnError: true,
  });
}
