import axios from "axios";
// import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

const addResolution = async (request) => {
  const { data: response } = await axios
    .post("/api/v1/resolutions", request)
    .then((res) => res);
  return response;
};

export default function useAddResolution() {
  const queryClient = useQueryClient();
  return useMutation((variables) => addResolution(variables), {
    // onSuccess: (data, variables, context) => {},
    onError: async (err, variables, previousValue) => {
      queryClient.setQueryData("resolutions", previousValue);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries("resolutions");
    },
    throwOnError: true,
  });
}
