import { useParams } from "react-router";
import useResolution from "../../resolutions/hooks/useResolution";

import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../shared/util/formatDate";
import useDeleteResolution from "../../resolutions/hooks/useDeleteResolution";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";

const ViewResolution = ({ eventSlug, eventTitle }) => {
  const { resolutionId } = useParams();
  const navigate = useNavigate();
  const { data: resolution } = useResolution(resolutionId);

  const { authState } = useContext(AuthContext);

  const { mutateAsync: deleteResolution } = useDeleteResolution();

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: eventTitle, location: `events/${eventSlug}` },
      {
        text: "Résolution",
        location: `/events/${eventSlug}/resolution/${resolutionId}`,
      },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb, eventSlug, resolutionId, eventTitle]);

  if (resolution.length === 0) {
    return "Loading...";
  }

  const onDeleteButtonClick = async (resolutionId) => {
    try {
      await deleteResolution({ resolutionId });
      navigate(`/app/events/${eventSlug}`);
    } catch (error) {
      console.log(error);
    }
  };

  let translationStatus = "Incomplète";

  if (resolution.title_en && resolution.content_en) {
    translationStatus = "Complète";
    resolution.whereas.map((where) => {
      if (!where.title_en) {
        translationStatus = "Incomplète";
      }
      return translationStatus;
    });
  }

  return (
    <>
      <div className="uk-card uk-card-body uk-card-default uk-margin-top">
        <div data-uk-grid>
          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-middle uk-flex-between">
              <h3 className="uk-margin-remove">Voir la résolution</h3>
              <div>
                {(authState.userInfo.role === "admin" ||
                  authState.userInfo.role === "reviseur") && (
                  <button
                    onClick={() => {
                      navigate(
                        `/app/events/${eventSlug}/resolution/edit/${resolution.id}`
                      );
                    }}
                    type="button"
                    className="uk-button uk-button-primary uk-button-small"
                  >
                    Modifier
                  </button>
                )}
                {authState.userInfo.role !== "read" && (
                  <button
                    type="button"
                    className="uk-button uk-button-primary uk-button-small uk-margin-small-left"
                    onClick={() => {
                      navigate(
                        `/app/events/${eventSlug}/resolution/translate/${resolution.id}`
                      );
                    }}
                  >
                    Traduire
                  </button>
                )}

                {authState.userInfo.role === "admin" && (
                  <button
                    className="uk-button uk-button-secondary uk-button-small uk-margin-small-left"
                    onClick={(e) =>
                      window.confirm(
                        "Are you sure you wish to delete this item?"
                      ) && onDeleteButtonClick(resolution.id)
                    }
                  >
                    Supprimer
                  </button>
                )}
              </div>
            </div>
            <hr />
          </div>
        </div>
        <div className="uk-margin-small uk-grid-divider" data-uk-grid>
          <div className="uk-width-1-2@l uk-width-2-3@xl">
            <div className="">
              <h3 className="uk-margin-remove">{resolution.title}</h3>

              <ul className="uk-list">
                {resolution.whereas.map((wherea, index) => (
                  <div key={index}>
                    ATTENDU QUE <br /> {wherea.title}
                  </div>
                ))}
              </ul>

              {resolution.resolution_proposers.map((proposer) => (
                <div key={proposer.id}>
                  {proposer.instance.label}
                  {(proposer.district || proposer.region) && <> : </>}
                  {proposer.district_id
                    ? proposer.district?.label
                    : proposer.region?.label}{" "}
                </div>
              ))}
              <br />
              {resolution.resolution_proposers.length > 1
                ? "Proposent que :"
                : "Propose que :"}
              <div
                className="uk-margin-top"
                dangerouslySetInnerHTML={{ __html: resolution.content }}
              />
            </div>

            <hr />

            <div className="uk-margin-top">
              <div data-uk-alert>Version anglaise</div>
              <h3 className="uk-margin-remove">{resolution.title_en}</h3>

              <ul className="uk-list">
                {resolution.whereas.map((wherea, index) => (
                  <div key={index}>
                    WHEREAS
                    <br /> {wherea.title_en}
                  </div>
                ))}
              </ul>

              {resolution.resolution_proposers.map((proposer) => (
                <div key={proposer.id}>
                  {proposer.instance.label}
                  {(proposer.district || proposer.region) && <> : </>}
                  {proposer.district_id
                    ? proposer.district?.label
                    : proposer.region?.label}{" "}
                </div>
              ))}
              <br />
              {resolution.resolution_proposers.length > 1
                ? "proposing :"
                : "proposing :"}
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: resolution.content_en }}
              />
            </div>
          </div>
          <div className="uk-width-1-2@l uk-width-1-3@xl">
            <div className="">
              <dl className="uk-description-list">
                <dt>Est finale</dt>
                <dd>
                  <span
                    className={`uk-label ${
                      resolution.isFinal
                        ? "uk-label-success"
                        : "uk-label-danger"
                    }`}
                  >
                    {resolution.isFinal ? "Finale" : "Non finale "}
                  </span>
                </dd>
                <dt>État</dt>
                <dd>
                  <span
                    className={`uk-label ${
                      resolution.isRevised
                        ? "uk-label-success"
                        : "uk-label-danger"
                    }`}
                  >
                    {resolution.isRevised ? "Révisée" : "Non révisée "}
                  </span>
                </dd>
                <dt>Traduction</dt>
                <dd>
                  <span
                    className={`uk-label ${
                      translationStatus === "Complète"
                        ? "uk-label-success"
                        : "uk-label-danger"
                    }`}
                  >
                    {translationStatus}
                  </span>
                </dd>
                <dt>Parti</dt>
                <dd>{resolution.party.value}</dd>
                <dt>Date</dt>
                <dd>{formatDate(resolution.date)}</dd>
                <dt>Statut</dt>
                <dd>{resolution.status.label}</dd>
                <dt>Thèmes</dt>
                <dd>
                  {resolution.themes.map(
                    (theme, index) => (index ? ", " : "") + theme.label
                  )}
                </dd>
              </dl>

              {resolution.contacts.length > 0 ? (
                <div className="uk-margin">
                  {resolution.contacts.map((contact) => (
                    <div
                      key={contact.id}
                      className={`uk-margin-top ${
                        contact.isResource ? "" : ""
                      }`}
                    >
                      <b>
                        {contact.isResource
                          ? "Personne resource :"
                          : "Soumis par :"}
                      </b>{" "}
                      <br />
                      {contact.first_name} {contact.last_name} <br />
                      {contact.email} <br />
                      {contact.phone}
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <b>Aucune personne contact</b>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewResolution;
