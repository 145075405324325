import { Route, Routes } from "react-router-dom";
import { FormContextProvider } from "./context/form-context";
import Login from "./Login";

import Resolution from "./resolution/Index";
import ViewResolution from "./ViewResolution";

const Public = () => {
  return (
    <FormContextProvider>
      <Routes>
        <Route path=":eventSlug/*" element={<Resolution />} />
        <Route path="login" element={<Login />} />
        <Route path="public/resolution/:hashedId" element={<ViewResolution />} />
      </Routes>
    </FormContextProvider>
  );
};

export default Public;
