import React from "react";
import { Controller } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = (props) => {
  const { name, label, control, defaultValue = new Date(), required } = props;
  // console.log();
  // const dateReceived = watch("expiryAt");
  return (
    <div>
      <label className="uk-form-label" htmlFor={name}>
        {label}
        {required && <span className="tm-required"> *</span>}
      </label>

      <Controller
        control={control}
        name={name}
        defaultValue={new Date(defaultValue)}
        // {...register(name, { required })}
        required={required}
        render={({ field: { onChange, onBlur, value } }) => (
          <ReactDatePicker
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            dateFormat="yyyy-MM-dd"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            className="uk-input"
            valueName="selected" // DateSelect value's name is selected
            required={required}
          />
        )}
      />
    </div>
  );
};

export default DateInput;
