import axios from "axios";
import { useEffect, useState } from "react";
// import { useQuery } from "react-query";

const getEvents = async () => {
  const { data } = await axios.get("/api/v1/events/types");
  return data;
};

// Faire un hook qui regrouppe les listes ?
export const useEventTypesList = () => {
  const [types, setTypes] = useState([]);
  useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await getEvents();
        setTypes(
          response.map((resonse) => {
            var newObj = {};
            newObj["id"] = resonse.id;
            newObj["label"] = resonse.title;
            return newObj;
          })
        );
      } catch (error) {}
    }
    fetchInitialData();
  }, []);
  return { types };
};
