import { Routes } from "react-router-dom";

import Events from "./pages/events/Events";
import Status from "./pages/status/Status";
import Parties from "./pages/parties/Parties";
// import Resolutions from "./pages/resolutions/Resolutions";
import NavLink from "./shared/components/NavLink";
import Themes from "./pages/themes/Themes";
import Instances from "./pages/instances/Instances";
// import Dashboard from "./pages/dashboard/Dashboard";
import { useTitle } from "./shared/components/page-header/context/title-context";
import { ButtonBarProvider } from "./shared/components/page-header/context/buttons-bar-context";
import ButtonToolBar from "./shared/components/page-header/components/ButtonToolBar";
import Breadcrumbs from "./shared/components/page-header/components/Breadcrumbs";
import { BreadcrumbProvider } from "./shared/components/page-header/context/breadcrumb-context";
import Users from "./pages/users/Users";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import PrivateRoute from "./shared/components/PrivateRoute";

const Admin = () => {
  const { title, extra } = useTitle();

  const { authState, logout } = useContext(AuthContext);
  if (!authState.userInfo) {
    return <div>Loading...</div>;
  }



  return (
    <div>
      <main className="main-content">
        <nav className="sidebar">
          <div className="sidebar-title">Logo</div>
          <ul className="uk-nav uk-nav-default">
            {/* <NavLink to="resolutions" className="" activeClassName="uk-active">
              <span className="icon-menu">
                <i className="fad fa-file-alt"></i>
              </span>{" "}
              Résolutions
            </NavLink> */}

            {/* <NavLink to="/app" className="" activeClassName="uk-active">
              <span className="icon-menu">
                <i className="fad fa-table"></i>
              </span>{" "}
              Tableau de bord
            </NavLink> */}

            <NavLink to="events" className="" activeClassName="uk-active">
              <span className="icon-menu">
                <i className="fad fa-calendar-alt"></i>
              </span>{" "}
              Événements
            </NavLink>

            {authState.userInfo.role === "admin" && (
              <>
                <li className="uk-nav-divider"></li>

                <NavLink to="status" className="" activeClassName="uk-active">
                  <span className="icon-menu">
                    <i className="fad fa-grip-lines"></i>
                  </span>{" "}
                  Statuts
                </NavLink>

                <NavLink to="themes" className="" activeClassName="uk-active">
                  <span className="icon-menu">
                    <i className="fad fa-tags"></i>{" "}
                  </span>
                  Thèmes
                </NavLink>

                <NavLink to="parties" className="" activeClassName="uk-active">
                  <span className="icon-menu">
                    <i className="fad fa-vote-yea"></i>{" "}
                  </span>
                  Partis
                </NavLink>

                <NavLink
                  to="instances"
                  className=""
                  activeClassName="uk-active"
                >
                  <span className="icon-menu">
                    <i className="fad fa-podium"></i>{" "}
                  </span>
                  Instances
                </NavLink>
                <NavLink to="users" className="" activeClassName="uk-active">
                  <span className="icon-menu">
                    <i className="fad fa-users"></i>{" "}
                  </span>
                  Utilisateurs
                </NavLink>
              </>
            )}
          </ul>
          <div className="logout">
            <button
              onClick={logout}
              type="button"
              className="uk-button uk-button-link"
            >
              <span className="icon-menu">
                <i className="fad fa-power-off"></i>
              </span>{" "}
              Déconnexion
            </button>
          </div>
        </nav>
        <ButtonBarProvider>
          <BreadcrumbProvider>
            <div className="content-wrapper uk-padding">
              <Breadcrumbs />
              <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
                <div className="uk-flex uk-flex-middle">
                  <h1 className="uk-margin-remove uk-h2">{title}</h1>
                  {extra && <div className="uk-margin-left">{extra}</div>}
                </div>
                <div>
                  <ButtonToolBar />
                </div>
              </div>

              <Routes>
                {/* <Route path="/" element={<Navigate to="events" replace={true} />} /> */}
                {/* <Route path="/" element={<Dashboard />} /> */}
                <PrivateRoute
                  path="events/*"
                  element={<Events />}
                  isAuthenticated={authState.isAuthenticated}
                />
                {/* <Route path="resolutions/*" element={<Resolutions />} /> */}
                <PrivateRoute
                  path="status/*"
                  element={<Status />}
                  isAuthenticated={authState.isAuthenticated}
                />
                <PrivateRoute
                  path="parties/*"
                  element={<Parties />}
                  isAuthenticated={authState.isAuthenticated}
                />
                <PrivateRoute
                  path="themes/*"
                  element={<Themes />}
                  isAuthenticated={authState.isAuthenticated}
                />
                <PrivateRoute
                  path="instances/*"
                  element={<Instances />}
                  isAuthenticated={authState.isAuthenticated}
                />
                <PrivateRoute
                  path="users/*"
                  element={<Users />}
                  isAuthenticated={authState.isAuthenticated}
                />
              </Routes>
            </div>
          </BreadcrumbProvider>
        </ButtonBarProvider>
      </main>
    </div>
  );
};

export default Admin;
