import slugify from "react-slugify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DateInput from "../../../shared/components/DateInput";
import Input from "../../../shared/components/Input";
import useAddEvent from "../hooks/useAddEvent";
import { useEffect } from "react";
import { useEventTypesList } from "../hooks/useEventTypes";
import Select from "../../../shared/components/Select";

import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";

const NewEvent = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: {
      errors,
      // isValid,
    },
  } = useForm();
  const navigate = useNavigate();
  const watchTitle = watch("title");

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: "Ajouter un événement", location: "/events/add" },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb]);

  useEffect(() => {
    setValue("slug", slugify(watchTitle));
  }, [setValue, watchTitle]);

  const { mutateAsync: newEvent } = useAddEvent();
  const { types } = useEventTypesList();

  const onSubmit = async (values) => {
    try {
      await newEvent(values);
      navigate("/app/events");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="uk-card uk-card-body uk-card-default">
      <div data-uk-grid>
        <div className="uk-width-2-5@m">
          <h3 className="uk-margin-small">Ajouter un événement</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="uk-margin">
              <Input
                name="title"
                label="Nom de l'événement"
                register={register}
                required
                type="text"
                errors={errors["title"]}
              />
            </div>
            <div className="uk-margin">
              <Input
                name="slug"
                label="URL"
                register={register}
                type="text"
                errors={errors["slug"]}
                control={control}
                required
              />
            </div>
            <div className="uk-margin">
              <Input
                name="isActive"
                label="Est actif ?"
                register={register}
                type="checkbox"
                defaultValue={true}
                errors={errors["isActive"]}
                control={control}
              />
            </div>
            <div className="uk-margin">
              <DateInput name="date" label="Date" control={control} required />
            </div>
            <div className="uk-margin">
              <Select
                name="event_type_id"
                label="Type d'événement"
                control={control}
                type="select"
                options={types}
                register={register}
                required
              />
            </div>
            <button
              onClick={handleSubmit(onSubmit)}
              className="uk-button uk-button-primary"
            >
              Enregistrer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewEvent;
