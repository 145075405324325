import React, { createContext } from "react";
import axios from "axios";

const FetchContext = createContext();
const { Provider } = FetchContext;

const FetchProvider = ({ children }) => {
  const publicAxios = axios.create({
    baseURL: "/api/v1/",
  });
  const authAxios = axios.create({
    baseURL: "/api/v1/",
  });

  //   useEffect(() => {
  //     const getCsrfToken = async () => {
  //       try {
  //         const { data } = await publicAxios.get("/api/csrf-token");
  //         publicAxios.defaults.headers["X-CSRF-Token"] = data.csrfToken;
  //         authAxios.defaults.headers["X-CSRF-Token"] = data.csrfToken;
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     };
  //     getCsrfToken();
  //   }, [authAxios, publicAxios]);

  authAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const code = error && error.response ? error.response.status : 0;
      if (code === 401 || code === 403) {
        console.log("error code", code);
      }
      return Promise.reject(error);
    }
  );

  return (
    <Provider
      value={{
        authAxios,
        publicAxios,
      }}
    >
      {children}
    </Provider>
  );
};

export { FetchContext, FetchProvider };
