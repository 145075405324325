import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const getParties = async (isList) => {
  const { data } = await axios.get("/api/v1/parties", { params: isList });
  return data;
};

export default function useParties() {
  const fallback = [];
  const { data = fallback, status } = useQuery(
    ["parties"],
    () => getParties(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return { data, status };
}

// Faire un hook qui regrouppe les listes ?
export const usePartiesList = () => {
  const [parties, setParties] = useState([]);
  useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await getParties({ isList: true });
        setParties(response);
      } catch (error) {}
    }
    fetchInitialData();
  }, []);
  return { parties };
};
