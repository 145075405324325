import useStatus from "../hooks/useStatus";

const ViewStatus = () => {
  const { data: statuses, status } = useStatus();

  if (status === "loading" || !statuses) {
    return "Loading...";
  }

  if (statuses.length <= 0) {
    return (
      <div className="uk-text-center">
        <h3 className="uk-margin-small">Aucun statut</h3>
      </div>
    );
  }

  return (
    <div className="">
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
        <div>
          <h3 className="uk-margin-remove">Statuts</h3>
        </div>
      </div>
      <div className="uk-card uk-card-body uk-card-default ">
        <>
          <table className="uk-table uk-table-divider">
            <thead>
              <tr>
                <th>Nom</th>
              </tr>
            </thead>
            <tbody>
              {statuses.map((status) => (
                <tr key={status.id}>
                  <td>{status.label}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    </div>
  );
};

export default ViewStatus;
