import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, array } from "yup";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DistrictsData from "../../../data/districts.json";
import RegionsData from "../../../data/regions.json";
import { useData } from "../context/form-context";
import { useInstancesList } from "../../instances/hooks/useInstances";
// import Select from "../../../shared/components/Select";

const schema = object().shape({
  proposers: array().of(
    object().shape({
      id: number().integer().required(),
      district_id: number().when("id", {
        is: (value) => value === 2 || value === 8,
        then: number().integer().required(),
      }),
      region_id: number().when("id", {
        is: (value) => value === 1 || value === 3,
        then: number().integer().required(),
      }),
    })
  ),
});

const Step2 = ({ event }) => {
  const isMainParty = event.event_type_id === 1 || false;
  let { instances } = useInstancesList();
  if (isMainParty) {
    instances = instances.filter((instance) => instance.isMainPartyInstance);
  } else {
    instances = instances.filter((instance) => !instance.isMainPartyInstance);
  }

  const navigate = useNavigate();
  const { setValues, data, isStepValid, setIsValid } = useData();

  const {
    watch,
    register,
    formState: { isValid },
    handleSubmit,
    unregister,
    control,
  } = useForm({
    mode: "all",
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const {
    fields: proposerFields,
    append: addProposer,
    remove: removeProposer,
  } = useFieldArray({
    control,
    name: "proposers",
    keyName: "key",
  });

  const onSubmit = async (values) => {
    console.log(values);
    setValues(values);
    setIsValid((prevData) => ({
      ...prevData,
      1: { validity: isValid },
    }));
    navigate("../step-3");
  };

  useEffect(() => {
    if (!isStepValid[0].validity) {
      navigate(`/${event.slug}`);
    }
  }, [navigate, event.slug, isStepValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="uk-visibible uk-fieldset">
        <legend className="uk-legend">Proposé par :</legend>
        {proposerFields.map((proposedBy, index) => {
          const instanceId = watch(`proposers.${index}.id`);

          console.log(instanceId);

          return (
            <div className="uk-margin" key={index}>
              <div
                className="uk-flex uk-flex-bottom uk-grid-collapse"
                data-uk-grid
              >
                <div className="uk-width-1-1 uk-width-2-5@m">
                  <label htmlFor="">Instance :</label>
                  <div className="uk-form-controls">
                    <Controller
                      control={control}
                      name={`proposers.${index}.id`}
                      render={({ field }) => (
                        <select
                          {...field}
                          name={`proposers.${index}.id`}
                          id={`instance-${index}`}
                          className="uk-select instance"
                          onChange={(e) => {
                            unregister(`proposers.${index}.district_id`);
                            unregister(`proposers.${index}.region_id`);
                            field.onChange(e);
                          }}
                        >
                          <option value="" disabled>
                            Veuillez choisir
                          </option>
                          {instances.map((instance) => (
                            <option
                              key={instance.id}
                              value={instance.id}
                              data-is-regional={instance.isRegional}
                            >
                              {instance.label}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </div>
                </div>
                <div className="uk-width-1-1 uk-width-2-5@m">
                  {instanceId === 2 ||
                  instanceId === 8 ||
                  instanceId === "2" ||
                  instanceId === "8" ? (
                    <>
                      <label htmlFor="">Circonscription :</label>
                      <select
                        name="district_id"
                        id={`district-${index}`}
                        className="uk-select district"
                        {...register(`proposers.${index}.district_id`)}
                      >
                        <option value="">Veuillez choisir</option>
                        {DistrictsData.map((district) => (
                          <option key={district.id} value={district.id}>
                            {district.label}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : instanceId === 6 ||
                    instanceId === 5 ||
                    instanceId === 7 ||
                    instanceId === 4 ||
                    instanceId === 10 ||
                    instanceId === "6" ||
                    instanceId === "5" ||
                    instanceId === "7" ||
                    instanceId === "4" ||
                    instanceId === "10" ? (
                    ""
                  ) : (
                    <>
                      <label htmlFor="">Région :</label>
                      <select
                        name="region_id"
                        id={`region-${index}`}
                        className="uk-select region"
                        {...register(`proposers.${index}.region_id`)}
                      >
                        <option value="">Veuillez choisir</option>
                        {RegionsData.map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.label}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
                <div className="uk-width-expand@m">
                  {index !== 0 && (
                    <button
                      type="button"
                      className="uk-button uk-button-secondary button-action uk-width-1-1@m"
                      onClick={() => removeProposer(index)}
                    >
                      Supprimer
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <button
          type="button"
          className="uk-button uk-button-link"
          onClick={() => {
            addProposer({ id: "" });
          }}
        >
          + Ajouter un proposeur conjoint
        </button>
      </fieldset>

      <div className="uk-margin-top uk-clearfix">
        <hr />
        <div className="uk-flex uk-flex-between">
          <button
            type="button"
            onClick={() => navigate("../")}
            className="uk-button uk-button-default"
          >
            Étape précédente
          </button>
          <button
            disabled={!isValid}
            type="submit"
            className="uk-button uk-button-primary uk-float-right"
          >
            Prochaine étape
          </button>
        </div>
      </div>
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </form>
  );
};

export default Step2;
