import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router";
import Input from "../../../shared/components/Input";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";

import TextEditor from "../../../shared/components/TextEditor";
import useResolution from "../hooks/useResolution";
import useTranslateResolution from "../hooks/useTranslateResolution";

const EditResolutionTranslation = ({ eventSlug, eventTitle }) => {
  const { resolutionId } = useParams();
  const { data: resolution } = useResolution(resolutionId);
  const { mutateAsync: translateResolution } = useTranslateResolution();
  const navigate = useNavigate();
  const {
    register,
    // unregister,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    mode: "all",
  });

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: eventTitle, location: `events/${eventSlug}` },
      {
        text: "Résolution",
        location: `events/${eventSlug}/resolution/${resolution.id}`,
      },
      { text: "Traduction de la résolution", location: `events/${eventSlug}` },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb, eventSlug, eventTitle, resolution.id]);

  const onSubmit = async (data) => {
    try {
      await translateResolution({
        resolutionId,
        data,
      });
      navigate(`/app/events/${eventSlug}/resolution/${resolution.id}`);
    } catch (error) {}
  };

  if (resolution.length === 0) {
    return "Loading...";
  }

  return (
    <div className="uk-grid-small" data-uk-grid>
      <div className="uk-width-1-1">
        <div className="uk-card uk-card-body uk-card-default uk-margin-top">
          <div data-uk-grid>
            <div className="uk-width-1-2">
              <h4>Version française</h4>
            </div>
            <div className="uk-width-1-2">
              <h4>Version anglaise</h4>
            </div>
          </div>

          <hr />

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="uk-grid-small uk-grid-divider" data-uk-grid>
              <div className="uk-width-1-1">
                <h5 className="uk-margin-remove"> Attendu que</h5>
              </div>
            </div>
            {resolution.whereas.map((whereas, index) => (
              <div key={whereas.id} className="uk-grid-small" data-uk-grid>
                <div className="uk-width-1-2">
                  <>
                    {whereas.title}
                    <br />
                  </>
                </div>
                <div className="uk-width-1-2">
                  <Input
                    name={`whereas.${index}.title_en`}
                    // label="Attendu que"
                    register={register}
                    // required
                    type="text"
                    defaultValue={whereas.title_en}
                    errors={errors.whereas && errors.whereas[index]?.title}
                  />
                  <Input
                    name={`whereas.${index}.id`}
                    // label="Attendu que"
                    register={register}
                    // required
                    type="hidden"
                    defaultValue={whereas.id}
                    errors={errors.whereas && errors.whereas[index]?.title}
                  />
                </div>
              </div>
            ))}

            <hr />
            <div className="uk-grid-small" data-uk-grid>
              <div className="uk-width-1-1">
                <h5 className="uk-margin-remove">Titre</h5>
              </div>
              <div className="uk-width-1-2"> {resolution.title} </div>
              <div className="uk-width-1-2">
                <Input
                  name="title_en"
                  label=""
                  register={register}
                  defaultValue={resolution.title_en}
                  // required
                  type="text"
                  errors={errors?.title}
                />
              </div>
            </div>
            <hr />
            <div className="uk-grid-small" data-uk-grid>
              <div className="uk-width-1-1">
                <h5 className="uk-margin-remove">Texte de la résolution</h5>
              </div>
              <div className="uk-width-1-2">
                <div
                  className=""
                  dangerouslySetInnerHTML={{ __html: resolution.content }}
                />
              </div>
              <div className="uk-width-1-2">
                <TextEditor
                  control={control}
                  name="content_en"
                  label=""
                  register={register}
                  errors={errors["content"]}
                  defaultValue={resolution.content_en}
                  type="textarea"
                  options={{
                    readonly: false,
                  }}
                />
              </div>
            </div>
            <hr />
            <div className="uk-margin uk-text-right uk-flex uk-flex-right">
              <div>
                <button
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                  className="uk-button uk-button-default"
                >
                  Annuler
                </button>
              </div>
              <div>
                <button
                  className="uk-button uk-button-primary uk-margin-large-bottom uk-margin-left"
                  type="submit"
                >
                  Traduire
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditResolutionTranslation;
