import { Route, Routes } from "react-router-dom";
import ViewParties from "./index/ViewParties";

const Parties = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewParties />} />
    </Routes>
  );
};

export default Parties;
