import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const getEvents = async () => {
  const { data } = await axios.get("/api/v1/events");
  return data;
};

export default function useEvents() {
  const fallback = [];
  const { data = fallback, status } = useQuery(["events"], () => getEvents(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
  });

  return { data, status };
}

// Faire un hook qui regrouppe les listes ?
export const useEventsList = () => {
  const [events, setEvent] = useState([]);
  useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await getEvents();
        setEvent(
          response.map((resonse) => {
            var newObj = {};
            newObj["id"] = resonse.id;
            newObj["label"] = resonse.title;
            return newObj;
          })
        );
      } catch (error) {}
    }
    fetchInitialData();
  }, []);
  return { events };
};
