import useInstances from "../hooks/useInstances";

const ViewInstances = () => {
  const { data: instances, status } = useInstances();

  if (status === "loading" || !instances) {
    return "Loading...";
  }

  if (instances.length <= 0) {
    return (
      <div className="uk-text-center">
        <h3 className="uk-margin-small">Aucune instances</h3>
      </div>
    );
  }

  return (
    <div className="">
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
        <div>
          <h3 className="uk-margin-remove">Instances</h3>
        </div>
      </div>
      <div className="uk-card uk-card-body uk-card-default ">
        <>
          <table className="uk-table uk-table-divider">
            <thead>
              <tr>
                <th>id</th>
                <th>Nom</th>
                <th>Type d'instance</th>
                <th>Type instance du Parti ?</th>
              </tr>
            </thead>
            <tbody>
              {instances.map((instance) => (
                <tr key={instance.id}>
                  <td>{instance.id}</td>
                  <td>{instance.label}</td>
                  <td>{instance.instance_type.title}</td>
                  <td>{instance.isMainPartyInstance ? "Oui" : "Non"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    </div>
  );
};

export default ViewInstances;
