import { createContext, useContext, useState } from "react";

const BreadcrumbStateContext = createContext([]);
const BreadcrumbUpdateContext = createContext(() => {});

const BreadcrumbProvider = ({ children }) => {
  const [state, setState] = useState([]);
  return (
    <BreadcrumbStateContext.Provider value={state}>
      <BreadcrumbUpdateContext.Provider value={setState}>
        {children}
      </BreadcrumbUpdateContext.Provider>
    </BreadcrumbStateContext.Provider>
  );
};

function useBreadcrumb() {
  const context = useContext(BreadcrumbStateContext);
  if (context === undefined) {
    throw new Error("useButtons must be used within a ButtonBarProvider");
  }
  return context;
}

function useBreadcrumbSetter() {
  const context = useContext(BreadcrumbUpdateContext);
  if (context === undefined) {
    throw new Error(
      "BreadcrumbUpdateContext must be used within a ButtonBarProvider"
    );
  }
  return context;
}

export { BreadcrumbProvider, useBreadcrumb, useBreadcrumbSetter };
