import axios from "axios";
import { useQuery } from "react-query";

const getEvent = async (slug) => {
  const { data } = await axios.get(`/api/v1/events/${slug}`);
  return data;
};

export default function useEvent(slug) {
  const fallback = [];
  const { data = fallback, status } = useQuery(
    ["event", slug],
    () => getEvent(slug),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 55000,
    }
  );
  return { data, status };
}
