import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useButtonToolbarSetter } from "../../../shared/components/page-header/context/buttons-bar-context";
import { useUpdateTitle } from "../../../shared/components/page-header/context/title-context";
import useDeleteUser from "../hooks/useDeleteUser";
import useUsers from "../hooks/useUsers";

const ViewUsers = () => {
  const { data: users, status } = useUsers();

  const { mutateAsync: deleteUser } = useDeleteUser();

  const updateTitle = useUpdateTitle();
  useEffect(() => {
    updateTitle({ title: "Utilisateurs" });
  }, [updateTitle]);
  const setButtonToolBar = useButtonToolbarSetter();
  useEffect(() => {
    setButtonToolBar([
      <Link
        key="newUserButton"
        to="users/register"
        className="uk-button uk-button-primary"
      >
        Ajouter un utilisateur
      </Link>,
    ]);
    return () => {
      setButtonToolBar([]);
    };
  }, [setButtonToolBar]);

  const onDeleteButtonClick = async (userId, slug) => {
    try {
      await deleteUser({ userId });
    } catch (error) {
      console.log(error);
    }
  };

  if (status === "loading" || !users) {
    return "Loading...";
  }

  if (users.length <= 0) {
    return (
      <div className="uk-text-center">
        <h3 className="uk-margin-small">Aucun utilisateur</h3>
      </div>
    );
  }

  return (
    <div className="">
      <div className="uk-card uk-card-body uk-card-default ">
        <>
          <table className="uk-table uk-table-divider">
            <thead>
              <tr>
                <th>Prénom</th>
                <th>Nom</th>
                <th>Courriel</th>
                <th>Rôle</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{user.role}</td>
                  <td>
                    <Link to={`${user.id}`}>Voir</Link> -{" "}
                    {/* <Link to={`edit/${event.slug}`}>Modifier</Link> -{" "} */}
                    <button
                      className="uk-button uk-button-link"
                      onClick={(e) =>
                        window.confirm(
                          "Are you sure you wish to delete this item?"
                        ) && onDeleteButtonClick(user.id, user.slug)
                      }
                    >
                      Supprimer
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    </div>
  );
};

export default ViewUsers;
