import axios from "axios";
// import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

const addUser = async (request) => {
  const { data: response } = await axios
    .post("/api/v1/users/register", request)
    .then((res) => res);
  return response.data;
};

export default function useAddUsers() {
  const queryClient = useQueryClient();
  return useMutation((variables) => addUser(variables), {
    // onSuccess: (data, variables, context) => {},
    onError: async (err, variables, previousValue) => {
      queryClient.setQueryData("users", previousValue);
    },
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries("users");
    },
    throwOnError: true,
  });
}
