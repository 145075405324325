import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const getThemes = async (isList) => {
  const { data } = await axios.get("/api/v1/themes", { params: isList });
  return data;
};

export default function useThemes() {
  const fallback = [];
  const { data = fallback, status } = useQuery(["themes"], () => getThemes(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
  });

  return { data, status };
}

// Faire un hook qui regrouppe les listes ?
export const useThemesList = () => {
  const [themes, setThemes] = useState([]);
  useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await getThemes({ isList: true });
        setThemes(response);
      } catch (error) {}
    }
    fetchInitialData();
  }, []);
  return { themes };
};
