import { useParams } from "react-router";
import usePublicResolution from "./hooks/usePublicResolution";

const ViewResolution = () => {
  const { hashedId } = useParams();
  const { data: resolution, status } = usePublicResolution(hashedId);

  //   status;

  if (status !== "success") {
    return <div>Aucune résolution</div>;
  }

  return (
    <div className="uk-section">
      <div className="uk-container">
        <div className="uk-card uk-card-body uk-card-default ">
          <div className="">
            <h3 className="">{resolution.title}</h3>

            <ul className="uk-list">
              {resolution.whereas.map((wherea, index) => (
                <div key={index}>
                  ATTENDU QUE <br /> {wherea.title}
                </div>
              ))}
            </ul>

            {resolution.resolution_proposers.map((proposer) => (
              <div key={proposer.id}>
                {proposer.instance.label}
                {(proposer.district || proposer.region) && <> : </>}
                {proposer.district_id
                  ? proposer.district?.label
                  : proposer.region?.label}{" "}
              </div>
            ))}
            <br />
            {resolution.resolution_proposers.length > 1
              ? "Proposent que :"
              : "Propose que :"}
            <div
              className="uk-margin-top ql-editor"
              dangerouslySetInnerHTML={{ __html: resolution.content }}
            />
          </div>

          <hr />

          <div className="uk-margin-top">
            <div data-uk-alert>Version anglaise</div>
            <h3 className="uk-margin-remove">{resolution.title_en}</h3>

            <ul className="uk-list">
              {resolution.whereas.map((wherea, index) => (
                <div key={index}>
                  WHEREAS
                  <br /> {wherea.title_en}
                </div>
              ))}
            </ul>

            {resolution.resolution_proposers.map((proposer) => (
              <div key={proposer.id}>
                {proposer.instance.label}
                {(proposer.district || proposer.region) && <> : </>}
                {proposer.district_id
                  ? proposer.district?.label
                  : proposer.region?.label}{" "}
              </div>
            ))}
            <br />
            {resolution.resolution_proposers.length > 1
              ? "proposing :"
              : "proposing :"}
            <div
              className="ql-editor"
              dangerouslySetInnerHTML={{ __html: resolution.content_en }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewResolution;
