import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const getStatus = async (isList) => {
  const { data } = await axios.get("/api/v1/status", { params: isList });
  return data;
};

export default function useStatus() {
  const fallback = [];
  const { data = fallback, status } = useQuery(["status"], () => getStatus(), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
  });

  return { data, status };
}

// Faire un hook qui regrouppe les listes ?
export const useStatusList = () => {
  const [status, setStatus] = useState([]);
  useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await getStatus({ isList: true });
        setStatus(response);
      } catch (error) {}
    }
    fetchInitialData();
  }, []);
  return { status };
};
