import axios from "axios";
import { useQuery } from "react-query";

const getResolution = async (hashedId) => {
  const { data } = await axios.get(`/api/v1/resolutions/public/${hashedId}`);
  return data;
};

export default function usePublicResolution(hashedId) {
  const fallback = [];
  const { data = fallback, status } = useQuery(
    ["resolution", hashedId],
    () => getResolution(hashedId),
    {
      // refetchOnWindowFocus: true,
      // keepPreviousData: false,
      // staleTime: 55000,
    }
  );
  return { data, status };
}
