import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";

// import DateInput from "../../../shared/components/DateInput";
import Input from "../../../shared/components/Input";
// import useAddEvent from "../hooks/useAddEvent";
import { useEffect } from "react";

import Select from "../../../shared/components/Select";

import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";
import useAddUsers from "../hooks/useAddUser";
import { useUpdateTitle } from "../../../shared/components/page-header/context/title-context";
import { yupResolver } from "@hookform/resolvers/yup";

const roles = [
  { id: "admin", label: "Admin" },
  { id: "reviseur", label: "Réviseur" },
  { id: "traducteur", label: "Traducteur" },
  { id: "read", label: "Lecture seule" },
];

const schema = object().shape({
  first_name: string().required("Veuillez entrer un prénom"),
  last_name: string().required("Veuillez entrer un nom"),
  role: string().required("Veuillez entrer un rôle"),
  email: string().required("Veuillez entrer un courriel"),
  // whereas: array().of(
  //   object().shape({
  //     title: string().required("Veuillez entrer du texte"),
  //   })
  // ),
  // content: string().required("Veuillez entrer le texte de votre résolution"),
  // party_id: number().integer().required("Veuillez choisir un parti"),
  // status_id: number().integer().required("Veuillez choisir un status"),
  // event_id: number().integer().required("Veuillez choisir un événement"),
});

const defaultValues = {
  first_name: "",
  last_name: "",
  role: "",
  // events_expenses_type_id: "",
  // district_id: "",
  // date: new Date(),
  // reference: "",
  // amount: "",
};

const Register = () => {
  const updateTitle = useUpdateTitle();
  useEffect(() => {
    updateTitle({ title: "Utilisateurs" });
  }, [updateTitle]);
  const {
    register,
    handleSubmit,
    control,

    formState: {
      errors,
      //   // isValid,
    },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const navigate = useNavigate();

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Utilisateurs", location: "users" },
      { text: "Ajouter un utilisateur", location: "/users/register" },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb]);

  const { mutateAsync: newUser } = useAddUsers();

  const onSubmit = async (values) => {
    try {
      await newUser(values);
      navigate("/app/users");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="uk-card uk-card-body uk-card-default">
      <div data-uk-grid>
        <div className="uk-width-2-5@m">
          <h3 className="uk-margin-small">Ajouter un événement</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="uk-grid-small" data-uk-grid>
              <div className="uk-width-1-2@s">
                <Input
                  control={control}
                  name="first_name"
                  label="Prénom"
                  register={register}
                  errors={errors["first_name"]}
                  type="text"
                  required
                />
              </div>
              <div className="uk-width-1-2@s">
                <Input
                  name="last_name"
                  label="Nom de famille"
                  register={register}
                  control={control}
                  errors={errors["last_name"]}
                  required
                  type="text"
                />
              </div>
              <div className="uk-width-1-2@s">
                <Select
                  name="role"
                  label="Role"
                  control={control}
                  type="select"
                  options={roles}
                  register={register}
                  required
                />
              </div>
              <div className="uk-width-1-2@s">
                <Input
                  name="email"
                  label="Courriel"
                  register={register}
                  control={control}
                  errors={errors["email"]}
                  required
                  type="email"
                />
              </div>
              <div className="uk-width-1-2@s">
                <Input
                  name="phone"
                  label="Téléphone"
                  register={register}
                  control={control}
                  errors={errors["phone"]}
                  type="text"
                />
              </div>

              <div className="uk-width-1-2@s">
                <Input
                  name="username"
                  label="Nom d'utilisateur"
                  register={register}
                  control={control}
                  errors={errors["username"]}
                  // required
                  type="text"
                />
              </div>
              <Input
                name="password"
                label="Mot de passe"
                register={register}
                control={control}
                errors={errors["password"]}
                required
                type="password"
              />
            </div>
            <div className="uk-margin-top">
              <button
                onClick={handleSubmit(onSubmit)}
                className="uk-button uk-button-primary"
              >
                Enregistrer
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Register;
