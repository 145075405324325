import { createContext, useContext, useState } from "react";

const FormContext = createContext();
const { Provider } = FormContext;

const FormContextProvider = ({ children }) => {
  const [data, setData] = useState({
    contacts: [{ first_name: "" }],
    proposers: [{ id: "" }],
    whereas: [{ title: "", order: "0" }],
  });

  const [isStepValid, setIsValid] = useState([
    { validity: false },
    { validity: false },
    { validity: false },
  ]);

  const setValues = (values) => {
    setData((prevData) => ({
      ...prevData,
      ...values,
    }));
  };

  console.log({ data });

  return (
    <Provider
      value={{
        data,
        setValues,
        isStepValid,
        setIsValid,
        setData,
      }}
    >
      {children}
    </Provider>
  );
};

const useData = () => useContext(FormContext);

export { FormContextProvider, useData };
