import { createContext, useContext, useState } from "react";

const ButtonBarStateContext = createContext([]);
const ButtonBarUpdateContext = createContext(() => {});

const ButtonBarProvider = ({ children }) => {
  const [state, setState] = useState([]);
  return (
    <ButtonBarStateContext.Provider value={state}>
      <ButtonBarUpdateContext.Provider value={setState}>
        {children}
      </ButtonBarUpdateContext.Provider>
    </ButtonBarStateContext.Provider>
  );
};

function useButtons() {
  const context = useContext(ButtonBarStateContext);
  if (context === undefined) {
    throw new Error("useButtons must be used within a ButtonBarProvider");
  }
  return context;
}

function useButtonToolbarSetter() {
  const context = useContext(ButtonBarUpdateContext);
  if (context === undefined) {
    throw new Error(
      "useButtonToolbarSetter must be used within a ButtonBarProvider"
    );
  }
  return context;
}

export { ButtonBarProvider, useButtons, useButtonToolbarSetter };
