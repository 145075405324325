import axios from "axios";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/AuthContext";
import Input from "../../shared/components/Input";

const Login = () => {
  const { register, handleSubmit } = useForm();
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const onSubmit = async (event) => {
    try {
      const { data: response } = await axios
        .post("/api/v1/users/authenticate", event)
        .then((res) => res);

      if (response) {
        authContext.setAuthState(response);
      }

      //   setTimeout(() => {
      navigate("/app/events");
      //   }, 700);

      //   console.log({ response });

      //   const responseData = await mutateAsync(event);
      //   if (responseData) {
      //     authContext.setAuthState(responseData.data);
      //     if (redirectTo) {
      //       navigate(redirectTo);
      //       localStorage.removeItem("redirectTo");
      //     } else {
      //       navigate("/app/dashboard");
      //     }
      //   }
    } catch (error) {}
  };

  return (
    <div className="uk-container">
      <div className="uk-flex uk-flex-middle" data-uk-height-viewport>
        <div className="uk-margin-auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="uk-grid-small" data-uk-grid>
              <div className="uk-width-1-1@s">
                <h3>Connexion</h3>
                <Input
                  name="email"
                  label="Courriel"
                  register={register}
                  required
                  type="email"
                />
                {/* <Input name="username" label="Nom" register={register} required type="text" /> */}
              </div>
              <div className="uk-width-1-1@s">
                <Input
                  name="password"
                  label="Mot de passe"
                  register={register}
                  required
                  type="password"
                />
              </div>
            </div>
            <div className="uk-margin">
              <input
                type="submit"
                className="uk-button uk-button-primary"
                value="Connexion"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
