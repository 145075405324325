import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

export const getInstances = async (isList) => {
  const { data } = await axios.get("/api/v1/instances", { params: isList });
  return data;
};

export default function useInstances() {
  const fallback = [];
  const { data = fallback, status } = useQuery(
    ["instances"],
    () => getInstances(),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
    }
  );

  return { data, status };
}

// Faire un hook qui regrouppe les listes ?
export const useInstancesList = () => {
  const [instances, setInstances] = useState([]);
  useEffect(() => {
    async function fetchInitialData() {
      try {
        const response = await getInstances({ isList: true });
        setInstances(response);
      } catch (error) {}
    }
    fetchInitialData();
  }, []);
  return { instances };
};
