import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array } from "yup";

import Input from "../../../shared/components/Input";
import { useData } from "../context/form-context";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import TextEditor from "../../../shared/components/TextEditor";

const schema = object().shape({
  title: string().required("Veuillez entrer un titre à votre résolution"),
  whereas: array().of(
    object().shape({
      title: string().required("Veuillez entrer du texte"),
    })
  ),
  content: string().required("Veuillez entrer le texte de votre résolution"),
});

const Step3 = ({ eventSlug }) => {
  const navigate = useNavigate();
  const { setValues, data, isStepValid, setIsValid } = useData();

  const {
    // watch,
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: "all",
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const {
    fields: whereasFields,
    append: addWhereas,
    remove: removewWhereas,
  } = useFieldArray({
    control,
    name: "whereas",
  });

  const onSubmit = async (values) => {
    try {
      setValues(values);
      setIsValid((prevData) => ({
        ...prevData,
        2: { validity: isValid },
      }));
      navigate("../summary");
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!isStepValid[0].validity) {
      navigate(`/${eventSlug}`);
    }
  }, [navigate, eventSlug, isStepValid]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="uk-visibible uk-fieldset">
        <legend className="uk-legend">Votre résolution</legend>
        <Input
          name="title"
          label="Titre"
          register={register}
          required
          type="text"
          errors={errors?.title}
        />

        {whereasFields.map((motion, index) => {
          return (
            <div className="uk-margin" key={motion.id}>
              <div className="uk-flex uk-flex-top">
                <div className="uk-width-expand">
                  <Input
                    name={`whereas.${index}.title`}
                    label="Attendu que"
                    register={register}
                    required
                    type="text"
                    errors={errors.whereas && errors.whereas[index]?.title}
                  />
                  {/* <Input
                            name={`whereas.${index}.order`}
                            label="Order"
                            register={register}
                            // {...register(`whereas.${index}.order`, {
                            //   required: "Ce champs est requis",
                            // })}
                            required
                            defaultValue={index}
                            type="text"
                          /> */}
                </div>

                <div className="">
                  {index !== 0 && (
                    <button
                      type="button"
                      className="uk-button uk-button-secondary button-action"
                      onClick={() => {
                        removewWhereas(index);
                        // whereasFields.map(
                        //   (whereas, indexA) =>
                        //     index === indexA &&
                        //     setValue(`whereas.${indexA}.order`, indexA)
                        // );
                      }}
                    >
                      Supprimer
                    </button>
                  )}
                </div>
              </div>
            </div>
          );
        })}

        <button
          type="button"
          className="uk-button uk-button-link"
          onClick={() => {
            whereasFields.map((whereas, indexA) =>
              setValue(`whereas.${indexA}.order`, indexA)
            );
            addWhereas({
              title: "",
              order: whereasFields.length,
            });
          }}
        >
          + Ajouter un attendu que
        </button>
        <div className="uk-margin">
          <TextEditor
            control={control}
            name="content"
            label="Texte de la résolution"
            register={register}
            errors={errors["content"]}
            type="textarea"
            options={{
              readonly: false,
            }}
          />
        </div>
      </fieldset>
      <div className="uk-margin-top uk-clearfix">
        <hr />
        <div className="uk-flex uk-flex-between">
          <button
            type="button"
            onClick={() => navigate("../step-2")}
            className="uk-button uk-button-default"
          >
            Étape précédente
          </button>
          <button
            disabled={!isValid}
            type="submit"
            className="uk-button uk-button-primary uk-float-right"
          >
            Aperçu avant envoi
          </button>
        </div>
      </div>

      {/* <div className="uk-clearfix"></div> */}
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </form>
  );
};

export default Step3;
