import { Route, Routes } from "react-router-dom";
import ViewThemes from "./index/ViewThemes";

const Themes = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewThemes />} />
    </Routes>
  );
};

export default Themes;
