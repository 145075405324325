import { Route, Routes } from "react-router-dom";
import Register from "./add/Register";
import ViewUsers from "./index/ViewUsers";

const Users = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewUsers />} />
      <Route path="/register" element={<Register />} />
    </Routes>
  );
};

export default Users;
