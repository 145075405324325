import axios from "axios";
// import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";

const addResolution = async (request, eventSlug) => {
  // console.log({request});
  const { data: response } = await axios
    .post(`/api/v1/resolutions/${eventSlug}`, request)
    .then((res) => res);
  return response.data;
};

export default function useAddResolution(eventSlug) {
  const queryClient = useQueryClient();
  return useMutation((variables) => addResolution(variables, eventSlug), {
    // onSuccess: (data, variables, context) => {},
    onError: async (err, variables, previousValue) => {
      queryClient.setQueryData("resolutions", previousValue);
    },
    onSettled: (variables) => {
      queryClient.invalidateQueries("resolutions");
    },
    throwOnError: true,
  });
}
