import useThemes from "../hooks/useThemes";

const ViewThemes = () => {
  const { data: themes, status } = useThemes();

  if (status === "loading" || !themes) {
    return "Loading...";
  }

  if (themes.length <= 0) {
    return (
      <div className="uk-text-center">
        <h3 className="uk-margin-small">Aucun thème</h3>
      </div>
    );
  }

  return (
    <div className="">
      <div className="uk-flex uk-flex-between uk-flex-middle uk-margin-bottom">
        <div>
          <h3 className="uk-margin-remove">Thèmes</h3>
        </div>
      </div>
      <div className="uk-card uk-card-body uk-card-default ">
        <>
          <table className="uk-table uk-table-divider">
            <thead>
              <tr>
                <th>Nom</th>
              </tr>
            </thead>
            <tbody>
              {themes.map((status) => (
                <tr key={status.id}>
                  <td>{status.label}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      </div>
    </div>
  );
};

export default ViewThemes;
