import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../context/form-context";
// import InstancesData from "../../../data/instances.json";
import { useInstancesList } from "../../instances/hooks/useInstances";
import DistrictsData from "../../../data/districts.json";
import RegionsData from "../../../data/regions.json";
import useAddResolution from "./hooks/useAddResolution";

const Summary = ({ eventSlug, setIsCompleted }) => {
  const navigate = useNavigate();
  const { data, setData, isStepValid, setIsValid } = useData();
  let { instances } = useInstancesList();

  useEffect(() => {
    if (!isStepValid[0].validity) {
      navigate(`/${eventSlug}`);
    }
  }, [navigate, eventSlug, isStepValid]);

  const { mutateAsync: newResolution } = useAddResolution(eventSlug);

  const onSubmit = async () => {
    try {
      // console.log(data);
      await newResolution(data);
      setIsCompleted(true);
      setData({
        contacts: [{ first_name: "" }],
        proposers: [{ id: "" }],
        whereas: [{ title: "", order: "0" }],
      });
      setIsValid([
        { validity: false },
        { validity: false },
        { validity: false },
      ]);
      navigate(`/${eventSlug}`);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <h1 className="uk-h3">{data.title}</h1>
      <ul className="uk-list">
        {data.whereas.map((wherea, index) => (
          <div key={index}>
            <b>ATTENDU QUE</b> <br /> {wherea.title}
          </div>
        ))}
      </ul>
      <p>
        {data.proposers.map((proposer, index) => {
          const instance = instances.find(
            (instance) => instance.id === proposer.id
          );
          let place;
          if (
            instance?.id === 1 ||
            instance?.id === 3
          ) {
            place = RegionsData.find(
              (region) => region.id === proposer.region_id
            );
          } else {
            place = DistrictsData.find(
              (district) => district.id === proposer.district_id
            );
          }

          return (
            <span key={index}>
              <b>
                {instance?.label} {place && ': ' + place?.label}
              </b>{" "}
              {data.proposers.length > index + 1 ? <br /> : ""}
            </span>
          );
        })}
        {data.proposers.length > 1 ? "proposent que :" : "propose que :"}
      </p>
      {/* <div dangerouslySetInnerHTML>{data.content}</div> */}
      {/* <pre>{JSON.stringify(data)}</pre> */}
      <div dangerouslySetInnerHTML={{ __html: data.content }} />

      <div className="uk-margin-top uk-clearfix">
        <hr />
        <div className="uk-flex uk-flex-between">
          <button
            type="button"
            onClick={() => navigate("../step-3")}
            className="uk-button uk-button-default"
          >
            Étape précédente
          </button>
          <button className="uk-button uk-button-primary" onClick={onSubmit}>
            Envoyer
          </button>
        </div>
      </div>
    </div>
  );
};

export default Summary;
