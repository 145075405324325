import { createContext, useContext, useState } from "react";

const TitleStateContext = createContext();
const TitleDispatchContext = createContext();

const TitleProvider = ({ children }) => {
  const [title, setTitle] = useState({
    title: "",
    extra: "",
  });
  return (
    <TitleStateContext.Provider value={title}>
      <TitleDispatchContext.Provider value={setTitle}>
        {children}
      </TitleDispatchContext.Provider>
    </TitleStateContext.Provider>
  );
};

function useTitle() {
  const context = useContext(TitleStateContext);
  // console.log({context});
  if (context === undefined) {
    throw new Error("useTitle must be used within a TitleProvider");
  }
  return context;
}

function useUpdateTitle() {
  const context = useContext(TitleDispatchContext);
  // console.log(context);
  if (context === undefined) {
    throw new Error("useUpdateTitle must be used within a TitleProvider");
  }
  return context;
}

export { TitleProvider, useTitle, useUpdateTitle };
