import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, string, array } from "yup";
import Input from "../../../shared/components/Input";
import DistrictsData from "../../../data/districts.json";
import RegionsData from "../../../data/regions.json";

import TextEditor from "../../../shared/components/TextEditor";
import { useNavigate } from "react-router";

import DateInput from "../../../shared/components/DateInput";

import Select from "../../../shared/components/Select";

import useEditResolution from "../hooks/useEditResolution";
import Checkbox from "../../../shared/components/Checkbox";
import useDeleteResolution from "../hooks/useDeleteResolution";
import { useInstancesList } from "../../instances/hooks/useInstances";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

const schema = object().shape({
  resolution_proposers: array().of(
    object().shape({
      instance_id: number().integer().required(),
      // district_id: number()
      //   .nullable()
      //   .when("instance_id", {
      //     is: (value) => value === 3,
      //     then: number().integer().required(),
      //   }),
      // region_id: number().when("instance_id", {
      //   is: (value) => value === 1 || value === 2 || value === 4,
      //   then: number().integer().required(),
      // }),
    })
  ),
  title: string().required("Veuillez entrer un titre à votre résolution"),
  whereas: array().of(
    object().shape({
      title: string().required("Veuillez entrer du texte"),
    })
  ),
  content: string().required("Veuillez entrer le texte de votre résolution"),
  party_id: number().integer().required("Veuillez choisir un parti"),
  status_id: number().integer().required("Veuillez choisir un status"),
  event_id: number().integer().required("Veuillez choisir un événement"),
});

const EditResolutioForm = (props) => {
  const { authState } = useContext(AuthContext);

  const {
    eventSlug,
    eventId,
    parties,
    status,
    themes,
    events,
    resolution,
    proposers,
    whereas,
    contacts,
    isMainParty,
  } = props;

  let { instances } = useInstancesList();

  if (isMainParty) {
    instances = instances.filter((instance) => instance.isMainPartyInstance);
  } else {
    instances = instances.filter((instance) => !instance.isMainPartyInstance);
  }

  const { mutateAsync: deleteResolution } = useDeleteResolution();

  const navigate = useNavigate();
  // const { mutateAsync: newResolution } = useAddResolution();
  const { mutateAsync: editResolution } = useEditResolution();

  const {
    watch,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      contacts: contacts,
      resolution_proposers: proposers,
      whereas: whereas,
    },
    resolver: yupResolver(schema),
  });

  const { fields: contactsFields } = useFieldArray({
    control,
    name: "contacts",
    keyName: "key",
  });

  const {
    fields: proposerFields,
    append: addProposer,
    remove: removeProposer,
  } = useFieldArray({
    control,
    name: "resolution_proposers",
    keyName: "key",
  });

  const {
    fields: whereasFields,
    append: addWhereas,
    remove: removewWhereas,
  } = useFieldArray({
    control,
    name: "whereas",
    keyName: "key",
  });

  const onDeleteButtonClick = async (resolutionId) => {
    try {
      await deleteResolution({ resolutionId });
      navigate(`/app/events/${eventSlug}`);
    } catch (error) {
      // console.log(error);
    }
  };

  // Il faut envoyer region_id, district_id null;

  // console.log(errors);
  const resolutionId = resolution.id;

  const onSubmit = async (data) => {
    // console.log(data);
    data.resolution_proposers.map((proposer, index) => {
      if (!proposer.district_id) {
        return (data.resolution_proposers[index].district_id = null);
      }
      if (!proposer.region_id) {
        return (data.resolution_proposers[index].region_id = null);
      }
      return "";
      // data.resolution_proposers[index].resolution_id = resolutionId;
    });
    try {
      await editResolution({ resolutionId, data });
      if (eventId) {
        navigate(`/app/events/${eventSlug}/resolution/${resolutionId}`);
      } else {
        navigate(`/app/resolutions`);
      }
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="uk-card uk-card-body uk-card-default uk-margin-top">
        <div className="uk-grid-divider" data-uk-grid>
          <div className="uk-width-1-1">
            <div className="uk-flex uk-flex-between uk-flex-middle">
              <div>
                <h3 className="uk-margin-remove">Modifier une résolution</h3>
              </div>
              {authState.userInfo.role === "admin" && (
                <button
                  className="uk-button uk-button-secondary uk-button-small"
                  onClick={(e) =>
                    window.confirm(
                      "Are you sure you wish to delete this item?"
                    ) && onDeleteButtonClick(resolution.id)
                  }
                >
                  Supprimer
                </button>
              )}
            </div>
            <hr />
          </div>
          <div className="uk-width-2-3@l">
            {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
            {contactsFields.length > 0 && (
              <fieldset className="uk-visibible uk-fieldset">
                <legend className="uk-legend">Personne ressource</legend>
                {contactsFields.map((contact, index) => (
                  <div
                    className="uk-child-width-1-2@m uk-grid-small uk-margin uk-grid uk-grid-stack"
                    key={contact.id}
                    data-uk-grid
                  >
                    {index === 1 && (
                      <div className="uk-width-1-1">
                        <hr />
                        <legend className="uk-legend">Utilisateur</legend>
                      </div>
                    )}
                    <Input
                      name={`contacts.[${index}].first_name`}
                      label="Prénom"
                      register={register}
                      required
                      type="text"
                      errors={
                        errors.contacts && errors.contacts[index]?.first_name
                      }
                    />
                    <Input
                      name={`contacts[${index}].last_name`}
                      label="Nom"
                      register={register}
                      required
                      type="text"
                      errors={
                        errors.contacts && errors.contacts[index]?.last_name
                      }
                    />
                    <div className="uk-width-1-1">
                      <Input
                        name={`contacts[${index}].email`}
                        label="Courriel"
                        register={register}
                        required
                        type="email"
                        errors={
                          errors.contacts && errors.contacts[index]?.email
                        }
                      />
                    </div>
                    <div>
                      <Input
                        name={`contacts[${index}].membership`}
                        label="Numéro de membre"
                        register={register}
                        // required={index < 1}
                        type="text"
                        errors={
                          errors.contacts && errors.contacts[index]?.membership
                        }
                      />
                    </div>
                    <div>
                      <Input
                        name={`contacts[${index}].phone`}
                        label="Numéro de cellulaire"
                        register={register}
                        required
                        type="text"
                        errors={
                          errors.contacts && errors.contacts[index]?.phone
                        }
                      />
                    </div>
                  </div>
                ))}
              </fieldset>
            )}
            <fieldset className="uk-visibible uk-fieldset">
              <legend className="uk-legend">Résolution proposée par :</legend>
              {proposerFields.map((proposedBy, index) => {
                const instanceId = watch(
                  `resolution_proposers.${index}.instance_id`
                );

                // console.log({ instanceId });

                return (
                  <div className="uk-margin" key={index}>
                    <div
                      className="uk-flex uk-flex-bottom uk-grid-collapse"
                      data-uk-grid
                    >
                      <div className="uk-width-1-1 uk-width-2-5@m">
                        {/* <label htmlFor="">Instance :</label> */}
                        <div className="uk-form-controls">
                          <Select
                            name={`resolution_proposers.${index}.instance_id`}
                            label="Instance :"
                            control={control}
                            type="select"
                            options={instances}
                            register={register}
                            required
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-1 uk-width-2-5@m">
                        {instanceId === 2 || instanceId === 8 ? (
                          <>
                            <Select
                              name={`resolution_proposers.${index}.district_id`}
                              label="Circonscription :"
                              control={control}
                              type="select"
                              options={DistrictsData}
                              register={register}
                              required
                              shouldUnregister={true}
                            />
                            <Input
                              name={`resolution_proposers.${index}.region_id`}
                              defaultValue={null}
                              register={register}
                              required
                              type="hidden"
                            />
                          </>
                        ) : instanceId === 6 ||
                          instanceId === 5 ||
                          instanceId === 7 ||
                          instanceId === 4 ||
                          instanceId === 10 ? (
                          <>
                            <Input
                              name={`resolution_proposers.${index}.region_id`}
                              defaultValue={null}
                              register={register}
                              required
                              type="hidden"
                            />
                            <Input
                              name={`resolution_proposers.${index}.district_id`}
                              defaultValue={null}
                              register={register}
                              required
                              type="hidden"
                            />
                          </>
                        ) : (
                          <>
                            <Select
                              name={`resolution_proposers.${index}.region_id`}
                              label="Région :"
                              control={control}
                              type="select"
                              options={RegionsData}
                              register={register}
                              required
                              shouldUnregister={true}
                            />
                            <Input
                              name={`resolution_proposers.${index}.district_id`}
                              defaultValue={null}
                              register={register}
                              required
                              type="hidden"
                            />
                          </>
                        )}
                      </div>

                      <div className="uk-width-expand@m">
                        {index !== 0 && authState.userInfo.role === "admin" && (
                          <button
                            type="button"
                            className="uk-button uk-button-secondary button-action uk-width-1-1@m"
                            onClick={() => {
                              removeProposer(index);
                            }}
                          >
                            Supprimer
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
              {authState.userInfo.role === "admin" && (
                <button
                  type="button"
                  className="uk-button uk-button-link"
                  onClick={() => {
                    addProposer({ instance_id: "" });
                  }}
                >
                  + Ajouter un proposeur conjoint
                </button>
              )}
            </fieldset>

            <fieldset className="uk-visibible uk-fieldset uk-margin">
              <legend className="uk-legend">
                Information de la résolution
              </legend>
              <Input
                name="title"
                label="Titre"
                defaultValue={resolution.title}
                register={register}
                required
                type="text"
                errors={errors?.title}
              />

              {whereasFields.map((motion, index) => {
                return (
                  <div className="uk-margin" key={motion.key}>
                    <div className="uk-flex uk-flex-top">
                      <div className="uk-width-expand">
                        <Input
                          name={`whereas.${index}.title`}
                          label="Attendu que"
                          register={register}
                          required
                          type="text"
                          errors={
                            errors.whereas && errors.whereas[index]?.title
                          }
                        />
                      </div>

                      <div className="">
                        {index !== 0 && authState.userInfo.role === "admin" && (
                          <button
                            type="button"
                            className="uk-button uk-button-secondary button-action"
                            onClick={() => {
                              removewWhereas(index);
                            }}
                          >
                            Supprimer
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {authState.userInfo.role === "admin" && (
                <button
                  type="button"
                  className="uk-button uk-button-link"
                  onClick={() => {
                    whereasFields.map((whereas, indexA) =>
                      setValue(`whereas.${indexA}.order`, indexA)
                    );
                    addWhereas({
                      title: "",
                      order: whereasFields.length,
                    });
                  }}
                >
                  + Ajouter un attendu que
                </button>
              )}
              <div className="uk-margin">
                <TextEditor
                  control={control}
                  defaultValue={resolution.content}
                  name="content"
                  label="Texte de la résolution"
                  register={register}
                  errors={errors["content"]}
                  type="textarea"
                  options={{
                    readonly: false,
                  }}
                />
              </div>
            </fieldset>
          </div>
          <div className="uk-width-1-3@l">
            <div data-uk-sticky="offset: 50; bottom: #bottom">
              <fieldset className="uk-visibible uk-fieldset">
                <legend className="uk-legend">Autres informations</legend>
                <div className="uk-margin">
                  <DateInput
                    name="date"
                    label="Date de l'ajout"
                    control={control}
                    required
                    defaultValue={resolution.date}
                  />
                </div>
                <div className="uk-margin">
                  <Select
                    name="party_id"
                    label="Parti :"
                    control={control}
                    defaultValue={resolution.party_id}
                    errors={errors["party_id"]}
                    type="select"
                    options={parties}
                    register={register}
                    required
                    isReadonly={isMainParty}
                  />
                </div>

                <div className="uk-margin">
                  <Select
                    name="status_id"
                    label="Statut :"
                    control={control}
                    defaultValue={resolution.status_id}
                    errors={errors["status_id"]}
                    type="select"
                    options={status}
                    register={register}
                    required
                  />
                </div>
                <div className="uk-margin">
                  <Select
                    name="event_id"
                    label="Événement :"
                    control={control}
                    defaultValue={resolution.event_id}
                    errors={errors["event_id"]}
                    type="select"
                    options={events}
                    register={register}
                    required
                    isReadonly={isMainParty}
                  />
                </div>

                <Select
                  control={control}
                  name="themes"
                  label="Thèmes"
                  register={register}
                  errors={errors["themes"]}
                  // required
                  type="select"
                  options={themes}
                  isMulti={true}
                  defaultValue={resolution.themes}
                />
                {/* {console.log(resolution.isRevised)} */}
                <div className="uk-margin">
                  <Checkbox
                    name="isFinal"
                    label="Est Finale ?"
                    register={register}
                    errors={errors["isFinal"]}
                    type="boolean"
                    checked={resolution.isFinal}
                  />
                </div>
                <div className="uk-margin">
                  <Checkbox
                    name="isRevised"
                    label="Est revisée ?"
                    register={register}
                    errors={errors["isRevised"]}
                    type="boolean"
                    checked={resolution.isRevised}
                  />
                </div>
              </fieldset>

              <div className="uk-margin uk-text-right uk-flex uk-flex-right">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="uk-button uk-button-default"
                  >
                    Annuler
                  </button>
                </div>
                <div>
                  <button
                    className="uk-button uk-button-primary uk-margin-large-bottom uk-margin-left"
                    type="submit"
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bottom"></div>
    </form>
  );
};

export default EditResolutioForm;
