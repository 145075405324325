import { Controller, useFieldArray, useForm } from "react-hook-form";
import Input from "../../../shared/components/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, array } from "yup";
import { useNavigate } from "react-router-dom";
import { useData } from "../context/form-context";

const schema = object().shape({
  contacts: array().of(
    object().shape({
      first_name: string().required("Le prénom est requis"),
      last_name: string().required("Le nom est requis"),
      email: string()
        .email("Courriel non valide")
        .required("Le courriel est requis"),
      phone: string().required("Le cellulaire est requis"),
    })
  ),
});

const Step1 = ({setIsCompleted}) => {
  const navigate = useNavigate();
  const { setValues, data, setIsValid } = useData();


  const {
    // watch,
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
  } = useForm({
    mode: "all",
    defaultValues: data,
    resolver: yupResolver(schema),
  });

  const {
    fields: contactsFields,
    append: addContacts,
    remove: removeContacts,
  } = useFieldArray({
    control,
    name: "contacts",
  });

  const onSubmit = async (values) => {
    setValues(values);
    setIsValid((prevData) => ({
      ...prevData,
      0: { validity: isValid },
    }));
    setIsCompleted(false)
    navigate("./step-2");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className="uk-visibible uk-fieldset">
        <legend className="uk-legend">Vos informations</legend>
        {contactsFields.map((contact, index) => (
          <div
            className="uk-child-width-1-2@m uk-grid-small uk-margin uk-grid uk-grid-stack"
            key={contact.id}
            data-uk-grid
          >
            {index === 1 && (
              <div className="uk-width-1-1">
                <hr />
                <legend className="uk-legend">
                  Informations de la personne ressource
                </legend>
              </div>
            )}
            <Input
              name={`contacts.[${index}].first_name`}
              label="Prénom"
              register={register}
              required
              type="text"
              errors={errors.contacts && errors.contacts[index]?.first_name}
            />

            <Input
              name={`contacts[${index}].last_name`}
              label="Nom"
              register={register}
              required
              type="text"
              errors={errors.contacts && errors.contacts[index]?.last_name}
            />

            <div className="uk-width-1-1">
              <Input
                name={`contacts[${index}].email`}
                label="Courriel"
                register={register}
                required
                type="email"
                errors={errors.contacts && errors.contacts[index]?.email}
              />
            </div>
            <div>
              <Input
                name={`contacts[${index}].membership`}
                label="Numéro de membre"
                register={register}
                // required={index < 1}
                type="text"
                errors={errors.contacts && errors.contacts[index]?.membership}
              />
            </div>
            <div>
              <Input
                name={`contacts[${index}].phone`}
                label="Numéro de cellulaire"
                register={register}
                required
                type="text"
                errors={errors.contacts && errors.contacts[index]?.phone}
              />
            </div>
            {index === 0 && (
              <div className="uk-margin-top">
                <label htmlFor="contacts[0].isResource">
                  Je suis la personne-ressource
                </label>
                <Controller
                  control={control}
                  name={`contacts[${index}].isResource`}
                  render={({ field }) => (
                    <input
                      className={"uk-checkbox uk-margin-small-left"}
                      id={`contacts[${index}].isResource`}
                      defaultChecked={true}
                      {...register(`contacts[${index}].isResource`)}
                      type="checkbox"
                      onChange={(e) => {
                        if (contactsFields.length > 1) {
                          removeContacts(1);
                        } else {
                          addContacts({ first_name: "" });
                        }
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </div>
            )}
            {index === 1 && (
              <div data-uk-hidden>
                <Input
                  name={`contacts[${index}].isResource`}
                  label="Personne ressource"
                  register={register}
                  required
                  type="hidden"
                  defaultValue={true}
                />
              </div>
            )}
          </div>
        ))}
      </fieldset>

      <div className="uk-margin-top uk-clearfix">
        <hr />
        <div className="">
          <button
            disabled={!isValid}
            type="sumbit"
            className="uk-button uk-button-primary uk-float-right"
          >
            Prochaine étape
          </button>
        </div>
      </div>
      {/* <div className="uk-clearfix"></div> */}
      {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
    </form>
  );
};

export default Step1;
