import { Navigate, Route, useLocation } from "react-router-dom";

const PrivateRoute = ({
  element: Component,
  isAuthenticated,
  path,
  ...rest
}) => {
  let location = useLocation();
  if (!isAuthenticated) {
    localStorage.setItem("redirectTo", location.pathname);
    return <Navigate to={{ pathname: "/login" }} />;
  }

  return <Route {...rest} path={path} element={Component} />;
};

export default PrivateRoute;
