import { Link, useLocation, useResolvedPath } from "react-router-dom";

const NavLink = ({ to, exact, className, activeClassName, ...rest }) => {
  let location = useLocation();
  let resolvedPath = useResolvedPath(to);

  const { pathname } = location;
  const splitLocation = pathname.split("/");

  let isActive;
  if (exact) {
    isActive = location.pathname === resolvedPath.pathname;
  } else {
    isActive = splitLocation[2] === resolvedPath.pathname.split("/")[2];
    // isActive = routeMatches.some(
    //   (match) => match.pathname === resolvedPath.pathname
    // );
  }

  let allClassNames = className + (isActive ? `${activeClassName}` : "");

  return (
    <li className={allClassNames}>
      <Link to={to} {...rest} />
    </li>
  );
};

export default NavLink;
