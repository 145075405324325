import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, number, string, array } from "yup";
import Input from "../../../shared/components/Input";

import DistrictsData from "../../../data/districts.json";
import RegionsData from "../../../data/regions.json";
import TextEditor from "../../../shared/components/TextEditor";
import { useNavigate } from "react-router";
import useAddResolution from "../hooks/useAddResolution";
import DateInput from "../../../shared/components/DateInput";

import { useStatusList } from "../../status/hooks/useStatus";
import { useInstancesList } from "../../instances/hooks/useInstances";
import { useThemesList } from "../../themes/hooks/useThemes";
import Select from "../../../shared/components/Select";
import { useEventsList } from "../../events/hooks/useEvents";
import { usePartiesList } from "../../parties/hooks/useParties";
import Checkbox from "../../../shared/components/Checkbox";
import { useBreadcrumbSetter } from "../../../shared/components/page-header/context/breadcrumb-context";
import { useEffect } from "react";

const schema = object().shape({
  proposers: array().of(
    object().shape({
      id: number().integer().required(),
      district_id: number().when("id", {
        is: (value) => value === 3,
        then: number().integer().required(),
      }),
      region_id: number().when("id", {
        is: (value) => value === 1 || value === 2 || value === 4,
        then: number().integer().required(),
      }),
    })
  ),
  title: string().required("Veuillez entrer un titre à votre résolution"),
  whereas: array().of(
    object().shape({
      title: string().required("Veuillez entrer du texte"),
    })
  ),
  content: string().required("Veuillez entrer le texte de votre résolution"),
  party_id: number().integer().required("Veuillez choisir un parti"),
  status_id: number().integer().required("Veuillez choisir un status"),
  event_id: number().integer().required("Veuillez choisir un événement"),
});

const NewResolution = (props) => {
  const { eventSlug, eventId, eventType, eventTitle } = props;
  const isMainParty = eventType === 1 || false;

  // console.log(isMainParty);
  const navigate = useNavigate();
  const { mutateAsync: newResolution } = useAddResolution();

  const setBreadcrumb = useBreadcrumbSetter();
  useEffect(() => {
    setBreadcrumb([
      { text: "Événement", location: "events" },
      { text: eventTitle, location: `events/${eventSlug}` },
      { text: "Ajouter une résolution", location: `events/${eventSlug}` },
    ]);
    return () => {
      setBreadcrumb([]);
    };
  }, [setBreadcrumb, eventSlug, eventTitle]);

  const { events } = useEventsList();
  const { parties } = usePartiesList();
  const { status } = useStatusList();
  const { themes } = useThemesList();
  let { instances } = useInstancesList();

  if (isMainParty) {
    instances = instances.filter((instance) => instance.isMainPartyInstance);
  } else {
    instances = instances.filter((instance) => !instance.isMainPartyInstance);
  }

  const {
    watch,
    register,
    // unregister,
    formState: { errors },
    handleSubmit,
    setValue,
    control,
  } = useForm({
    mode: "all",
    defaultValues: {
      proposers: [{ id: "" }],
      whereas: [{ title: "", order: "0" }],
    },
    resolver: yupResolver(schema),
  });

  const {
    fields: proposerFields,
    append: addProposer,
    remove: removeProposer,
  } = useFieldArray({
    control,
    name: "proposers",
    keyName: "key",
  });

  const {
    fields: whereasFields,
    append: addWhereas,
    remove: removewWhereas,
  } = useFieldArray({
    control,
    name: "whereas",
  });

  const onSubmit = async (data) => {
    try {
      const resolution = await newResolution(data);
      if (resolution) {
        if (eventId) {
          navigate(`/app/events/${eventSlug}/resolution/${resolution.id}`);
        } else {
          navigate(`/app/resolutions/${resolution.id}`);
        }
      }
    } catch (error) {}
  };

  // console.log(watch("proposers"));

  // useEffect(() => {
  //   const data = watch((value, { name, type }) =>
  //     console.log("watch callback", value, name, type)
  //   );
  //   return () => data.unsubscribe();
  // }, [watch]);

  // const test = (index) => {
  //   console.log({index});
  //   // unregister(`proposers.${index}.district_id`);
  // };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="uk-card uk-card-body uk-card-default uk-margin-top">
        <div className="uk-grid-divider" data-uk-grid>
          <div className="uk-width-2-3@l">
            <h3 className="">Ajouter une résolution</h3>
            <fieldset className="uk-visibible uk-fieldset">
              <legend className="uk-legend">Résolution proposée par :</legend>
              {proposerFields.map((proposedBy, index) => {
                // console.log(watch(`proposers`));
                // const subscription = watch((value, { name, type }));
                const instanceId = watch(`proposers.${index}.id`);
                // console.log({ instanceId });

                return (
                  <div className="uk-margin" key={index}>
                    <div
                      className="uk-flex uk-flex-bottom uk-grid-collapse"
                      data-uk-grid
                    >
                      <div className="uk-width-1-1 uk-width-2-5@m">
                        {/* <label htmlFor="">Instance :</label> */}
                        <div className="uk-form-controls">
                          <Select
                            name={`proposers.${index}.id`}
                            label="Instance :"
                            control={control}
                            type="select"
                            options={instances}
                            register={register}
                            required
                          />
                        </div>
                      </div>
                      <div className="uk-width-1-1 uk-width-2-5@m">
                        {/* {getValues("test");} */}
                        {/* <pre> */}
                        {/* {JSON.stringify(watch(`proposers.${index}`, "id"))} */}
                        {/* </pre> */}
                        {/* {watch(`proposers.${index}`) } */}
                        {instanceId === 2 || instanceId === 8 ? (
                          <Select
                            name={`proposers.${index}.district_id`}
                            label="Circonscription :"
                            control={control}
                            // errors={errors["event_id"]}
                            // errors={errors[`proposers.${index}.district_id`]}
                            type="select"
                            options={DistrictsData}
                            register={register}
                            required
                            shouldUnregister={true}
                          />
                        ) : instanceId === 6 ||
                          instanceId === 5 ||
                          instanceId === 7 ||
                          instanceId === 4 ||
                          instanceId === 10 ? (
                          ""
                        ) : (
                          <Select
                            name={`proposers.${index}.region_id`}
                            label="Région :"
                            control={control}
                            // defaultValue={resolution.event_id}
                            // errors={errors[`proposers.${index}.region_id`]}
                            type="select"
                            options={RegionsData}
                            register={register}
                            required
                            shouldUnregister={true}
                          />
                        )}
                      </div>
                      <div className="uk-width-expand@m">
                        {index !== 0 && (
                          <button
                            type="button"
                            className="uk-button uk-button-secondary button-action uk-width-1-1@m"
                            onClick={() => {
                              removeProposer(index);
                            }}
                          >
                            Supprimer
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
              <button
                type="button"
                className="uk-button uk-button-link"
                onClick={() => {
                  addProposer({ id: "" });
                }}
              >
                + Ajouter un proposeur conjoint
              </button>
            </fieldset>
            <fieldset className="uk-visibible uk-fieldset uk-margin">
              <legend className="uk-legend">
                Information de la résolution
              </legend>
              <Input
                name="title"
                label="Titre"
                register={register}
                required
                type="text"
                errors={errors?.title}
              />

              {whereasFields.map((motion, index) => {
                return (
                  <div className="uk-margin" key={index}>
                    <div className="uk-flex uk-flex-top">
                      <div className="uk-width-expand">
                        <Input
                          name={`whereas.${index}.title`}
                          label="Attendu que"
                          register={register}
                          required
                          type="text"
                          errors={
                            errors.whereas && errors.whereas[index]?.title
                          }
                        />
                      </div>

                      <div className="">
                        {index !== 0 && (
                          <button
                            type="button"
                            className="uk-button uk-button-secondary button-action"
                            onClick={() => {
                              removewWhereas(index);
                              whereasFields.map(
                                (whereas, indexA) =>
                                  index === indexA &&
                                  setValue(`whereas.${indexA}.order`, indexA)
                              );
                            }}
                          >
                            Supprimer
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              <button
                type="button"
                className="uk-button uk-button-link"
                onClick={() => {
                  whereasFields.map((whereas, indexA) =>
                    setValue(`whereas.${indexA}.order`, indexA)
                  );
                  addWhereas({
                    title: "",
                    order: whereasFields.length,
                  });
                }}
              >
                + Ajouter un attendu que
              </button>
              <div className="uk-margin">
                <TextEditor
                  control={control}
                  name="content"
                  label="Texte de la résolution"
                  register={register}
                  errors={errors["content"]}
                  type="textarea"
                  options={{
                    readonly: false,
                  }}
                />
              </div>
            </fieldset>
          </div>
          <div className="uk-width-1-3@l">
            <div data-uk-sticky="offset: 50; bottom: #bottom">
              <fieldset className="uk-visibible uk-fieldset">
                <legend className="uk-legend">Autres informations</legend>
                <div className="uk-margin">
                  <DateInput
                    name="date"
                    label="Date de l'ajout"
                    control={control}
                    required
                  />
                </div>
                <div className="uk-margin">
                  <Select
                    name="party_id"
                    label="Parti :"
                    control={control}
                    errors={errors["party_id"]}
                    type="select"
                    options={parties}
                    register={register}
                    required
                    defaultValue={isMainParty ? 1 : null}
                    isReadonly={isMainParty}
                  />
                </div>

                <div className="uk-margin">
                  <Select
                    name="status_id"
                    label="Statut :"
                    control={control}
                    errors={errors["status_id"]}
                    type="select"
                    options={status}
                    register={register}
                    required
                  />
                </div>
                <div className="uk-margin">
                  <Select
                    name="event_id"
                    label="Événement :"
                    control={control}
                    defaultValue={eventId}
                    errors={errors["event_id"]}
                    type="select"
                    options={events}
                    register={register}
                    required
                    isReadonly={isMainParty}
                  />
                </div>

                <Select
                  control={control}
                  name="themes"
                  label="Thèmes"
                  register={register}
                  errors={errors["themes"]}
                  // required
                  type="select"
                  options={themes}
                  isMulti={true}
                />
                <div className="uk-margin">
                  <Checkbox
                    name="isFinal"
                    label="Est Finale ?"
                    register={register}
                    errors={errors["isFinal"]}
                    type="boolean"
                  />
                </div>

                <div className="uk-margin">
                  <Checkbox
                    name="isRevised"
                    label="Est revisée ?"
                    register={register}
                    errors={errors["isRevised"]}
                    type="boolean"
                  />
                </div>
              </fieldset>
              <div className="uk-margin">
                <button
                  className="uk-button uk-button-primary uk-margin-large-bottom"
                  type="submit"
                >
                  Envoyer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="bottom"></div>
    </form>
  );
};

export default NewResolution;
