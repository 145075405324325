import { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Public from "./pages/public/Index";
import Admin from "./Admin";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import { TitleProvider } from "./shared/components/page-header/context/title-context";
import { FetchProvider } from "./context/FetchContext";
import { AuthProvider } from "./context/AuthContext";

const App = () => {
  UIkit.use(Icons);
  const queryClient = new QueryClient();

  return (
    <BrowserRouter>
      <Suspense fallback={"loading"}>
        <QueryClientProvider client={queryClient}>
          <FetchProvider>
            <AuthProvider>
              <TitleProvider>
                <Routes>
                  <Route path="/*" element={<Public />} />
                  <Route path="app/*" element={<Admin />} />
                </Routes>
              </TitleProvider>
            </AuthProvider>
          </FetchProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
