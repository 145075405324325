import { Route, Routes } from "react-router-dom";
import ViewInstances from "./index/ViewInstances";

const Instances = () => {
  return (
    <Routes>
      <Route path="/" element={<ViewInstances />} />
    </Routes>
  );
};

export default Instances;
