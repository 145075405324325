import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

const deleteUser = async (request) => {
  const { data: response } = await axios
    .delete(`/api/v1/users/delete/${request.userId}`)
    .then((res) => res);
  return response.data;
};

export default function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation((variables) => deleteUser(variables), {
    onError: (err, variables, previousValue) =>
      queryClient.setQueryData("user", previousValue),
    onSuccess: (data, variables, context) => {},
    onSettled: (data, error, variables, context) => {
      queryClient.invalidateQueries("users");
    },
    throwOnError: true,
  });
}
