const Checkbox = ({
    name,
    label,
    register,
    required,
    defaultValue = null,
    isReadonly,
    onChange,
    errors,
    checked,
  }) => {
    return (
      <>
        <label className="uk-form-label" htmlFor={name}>
          <div className="uk-form-controls tm-checkbox-container">
            <input
              className={"uk-checkbox " + (isReadonly && "uk-disabled")}
              id={name}
              {...register(name, { required })}
              readOnly={isReadonly}
              type="checkbox"
              defaultChecked={checked}
            />{" "}
            {label} {required && <span>*</span>}
            {errors && errors[name] && (
              <div className="uk-alert-danger uk-margin-small-top" data-uk-alert>
                {`${label} est requis.`}
              </div>
            )}
          </div>
        </label>
      </>
    );
  };
  
  export default Checkbox;
  